/* eslint-disable */
import React from 'react';
import styled, { css } from 'styled-components/macro';
import {
  fdsColorPrimaryWhite,
  fdsColorPrimaryGray20,
  fdsColorPrimaryGray60,
  primaryColorToken,
  fdsComponentBackgroundColorInformation
} from '@freightos/design-system/dist/tokens';

import { t } from 'utils/translationProvider';

import { moneyFormat } from 'utils/index';

import { useSelector, useDispatch } from 'microfronts-redux';
import { setSortingType } from 'results/results.actions';
import { selectedSortType, bestScoreQuotesValues } from 'results/results.selectors';
import { ResultsScreenValues } from 'slimSearch/constants';

const parseDays = (days = []) => {
  if (days.length === 0) {
    return '';
  }
  if (days.length === 1) {
    return `${days[0]} ${t('day', 'day')}`;
  }
  if (days.length === 2) {
    return `${days[0]}-${days[1]} ${t('days', 'days')}`;
  }
};

const Dot = () => <DotContent>&middot;</DotContent>;

export const SortOptionItem = ({ type, values = undefined, label }) => {
  const dispatch = useDispatch();
  const selectedSortingType = useSelector(selectedSortType);
  return (
    <SortOptionTitle
      selected={selectedSortingType === type}
      onClick={() => dispatch(setSortingType(type))}
    >
      <SortOptionLabel>{label}</SortOptionLabel>
      {values ? (
        <>
          <DaysContent>
            <Dot />
            {parseDays(values.transitTime)}
          </DaysContent>
          <PriceContent>
            <Dot />
            {moneyFormat({ amount: parseInt(values?.cost || 0), currency: values?.currency })}
          </PriceContent>
        </>
      ) : null}
    </SortOptionTitle>
  );
};

export const SortOptionsComponent = () => {
  const bestScoreValues = useSelector(bestScoreQuotesValues);

  return (
    <SortContainer>
      <SortOptionItem
        label={t('bestValue', 'Best value')}
        type="bestValue"
        values={bestScoreValues.bestValueQuote}
      />
      <SortOptionItem
        label={t('quickest', 'Quickest')}
        type="quickest"
        values={bestScoreValues.quickestQuote}
      />
      <SortOptionItem
        label={t('cheapest', 'Cheapest')}
        type="cheapest"
        values={bestScoreValues.cheapestQuote}
      />
      <SortOptionItem
        label={t('greenest', 'Greenest')}
        type="greenest"
        values={bestScoreValues.greenestQuote}
      />
    </SortContainer>
  );
};

export default SortOptionsComponent;

const DaysContent = styled.span`
  @media (max-width: ${ResultsScreenValues.midDesktop}px) {
    display: none;
  }
`;

const PriceContent = styled.span`
  @media (max-width: ${ResultsScreenValues.tablet}px) {
    display: none;
  }
`;

const DotContent = styled.span`
  margin-left: 2px;
  margin-right: 2px;
`;

const SortContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex: 1;
  @media (max-width: ${ResultsScreenValues.tablet}px) {
    display: none;
  }
`;

const SortOptionLabel = styled.span`
  font-weight: bold;
  white-space: pre;
`;

const SortOptionTitle = styled.div`
  padding: 0 12px;
  border: 1px solid ${fdsColorPrimaryGray20};
  color: ${fdsColorPrimaryGray60};
  background: ${fdsColorPrimaryWhite};
  height: 25px;
  cursor: pointer;
  font-size: 11px;
  margin: 0;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ selected }) =>
    selected &&
    css`
      border: 1px solid ${primaryColorToken};
      color: ${primaryColorToken};
      background: ${fdsComponentBackgroundColorInformation};
    `}

  &:first-child {
    border-radius: 50px 0 0 50px;
  }
  &:last-child {
    border-radius: 0 50px 50px 0;
  }
  @media (min-width: ${ResultsScreenValues.tablet}px) {
    font-size: 12px;
  }
`;
