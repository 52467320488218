import { Button, Icon, Popover, RadioGroup } from '@freightos/design-system';
import { actions as layoutActions } from 'layout';
import { connect } from 'microfronts-redux';
import React from 'react';
import { updateField } from 'slimSearch/actions';
import { ISF_BOND } from 'slimSearch/constants';
import { bondTypeSelector, quoteSelector } from 'slimSearch/selectors';
import {
  getGoodsValue,
  getSingleEntryBondMaximumValue
} from 'slimSearch/utils/recommendedServices.utils';
import styled from 'styled-components/macro';
import { moneyFormat } from 'utils';
import { t } from 'utils/translationProvider';

const { openHelpCenter } = layoutActions;

export const BondOptionsSelectionComponent = ({
  bondType = 'single',
  shipment,
  updateFieldAction,
  singleEntryBondValue = 0,
  openHelpCenterAction
}) => {
  const onSelectionChange = (key) => {
    let singleEntryBond;
    let continuousBond;
    if (key === 'single') {
      singleEntryBond = {
        value: getGoodsValue(shipment),
        currencyID: shipment.goods?.goodsValue?.currency
      };
      continuousBond = false;
    } else {
      singleEntryBond = false;
      continuousBond = true;
    }
    updateFieldAction('quote.declaredCustoms', {
      ...shipment.declaredCustoms,
      continuousBond,
      singleEntryBond
    });
  };

  const options = [
    {
      value: 'single',
      label: (
        <span data-test-id="bond-option-single-entry-bond">
          {t('services/customs_bond_singletry_select_header_text', 'Single entry bond')}{' '}
          <Popover
            overlayClassName="services-popover"
            content={
              <>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'services/customs_bond_singlentry_select_tooltip_text',
                      'Additional fees levied on goods subject to FDA, EPA, FCC, USDA approval (among others), which can make it cheaper to buy a continuous bond.'
                    )
                  }}
                />
                <Button
                  type="text"
                  onClick={() =>
                    openHelpCenterAction(
                      '71658-what-do-i-need-to-know-about-shipping-fda-regulated-goods'
                    )
                  }
                >
                  {t('Learn more', 'Learn more')}.
                </Button>
              </>
            }
          >
            <Icon type="help-circled" />
          </Popover>
        </span>
      ),
      description: (
        <span data-test-id="bond-option-single-entry-bond-description">
          {t(
            'services/customs_bond_singlentry_select_description_text',
            `Single-use bond. Costs 0.5% of goods’ value, plus duties above $10k. Ocean shipments need ISF bond (${moneyFormat(
              { amount: ISF_BOND }
            )}). Final cost may be subject to additional fees.`
          )}
        </span>
      ),
      extra: !isNaN(singleEntryBondValue)
        ? `${t('from', 'from')} ${moneyFormat({ amount: singleEntryBondValue })} ${
            singleEntryBondValue < 1000
              ? ' - ' + moneyFormat({ amount: singleEntryBondValue + ISF_BOND })
              : ''
          }`
        : t('seb/noGoodValues', 'Based on goods value')
    },
    {
      value: 'continuous',
      label: (
        <span data-test-id="bond-option-annual-bond">
          {t('services/customs_bond_continuous_select_header_text', 'Annual bond')}
        </span>
      ),
      description: (
        <span data-test-id="bond-option-annual-bond-description">
          {t(
            'services/customs_bond_continuous_select_description_text',
            'Valid for multiple imports within 12 months and can be used across multiple providers. No additional bond costs.'
          )}
        </span>
      ),
      extra: moneyFormat({ amount: 450 })
    }
  ];

  return (
    <StyledRadioGroup
      card
      value={bondType}
      options={options}
      onChange={(event) => onSelectionChange(event?.target?.value)}
    />
  );
};

const StyledRadioGroup = styled(RadioGroup)`
  margin-bottom: 8px;
`;

const mapStateToProps = (state) => {
  const shipment = quoteSelector(state);
  const singleEntryBondValue = getSingleEntryBondMaximumValue(shipment);

  return {
    shipment,
    bondType: bondTypeSelector(state),
    singleEntryBondValue
  };
};

export default connect(mapStateToProps, {
  updateFieldAction: updateField,
  openHelpCenterAction: openHelpCenter
})(BondOptionsSelectionComponent);
