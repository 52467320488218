import React from 'react';
import { connect } from 'microfronts-redux';
import styled, { css } from 'styled-components/macro';
import { Button } from '@freightos/design-system';
import { fdsInputBorderColorDefault } from '@freightos/design-system/dist/tokens';

import { updateField } from 'slimSearch/actions';
import { isMobileSelector } from 'slimSearch/selectors';
import { allSectionsValid, categoryNotValid, nextInvalidCategory } from 'slimSearch/utils';

export const SectionFooter = ({
  onAdd = undefined,
  onDone = undefined,
  isMobile,
  isAllSectionsValid = false,
  updateField,
  nextInvalid,
  categoryInvalid,
  activeSection
}) => {
  return (
    <Footer data-test-id={`section-footer`} isMobile={isMobile}>
      {onAdd && (
        <AddButton
          data-test-id={`section-footer-add-load`}
          size={isMobile ? 'large' : 'default'}
          block={isMobile}
          type="secondary"
          icon="plus"
          onClick={() => onAdd()}
        >
          Add Another Load
        </AddButton>
      )}
      {onDone && (
        <Button
          data-test-id={`section-footer-done-btn`}
          size={isMobile ? 'large' : 'default'}
          block={isMobile}
          disabled={categoryInvalid}
          onClick={() => {
            updateField(`pristine.${activeSection}`, false);

            if (isAllSectionsValid || isMobile) {
              updateField('activeSection', null);
            } else {
              onDone();
              updateField(`touched.${nextInvalid}`, true);
              updateField('activeSection', nextInvalid);
            }
          }}
          type="primary"
        >
          {isAllSectionsValid ? 'Done' : 'Confirm'}
        </Button>
      )}
    </Footer>
  );
};

const Footer = styled.div`
  background: white;
  border-top: 1px solid ${fdsInputBorderColorDefault};
  padding: 10px 24px;
  display: flex;
  justify-content: flex-end;
  margin: 0 0 0 -24px;
  z-index: 1;
  ${({ isMobile }) =>
    isMobile
      ? css`
          z-index: 9999 !important;
          width: calc(100% + 18px) !important;
        `
      : css`
          bottom: 0;
          position: sticky;
          height: 50px;
          width: calc(100% + 48px);
        `}
`;

const AddButton = styled(Button)`
  margin: 0 16px 0 0;
`;

const mapStateToProps = (store) => {
  const activeSection = store.search?.activeSection;
  return {
    activeSection,
    isMobile: isMobileSelector(store),
    isAllSectionsValid: allSectionsValid(store?.search),
    nextInvalid: nextInvalidCategory(store?.search),
    categoryInvalid: activeSection
      ? categoryNotValid(activeSection, store.search.quote[activeSection])
      : false
  };
};

export default connect(mapStateToProps, {
  updateField
})(SectionFooter);
