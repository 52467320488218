import React from 'react';
import ReactDOM from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';
import { connect } from 'microfronts-redux';
import { isFinite, isEmpty, toString, upperFirst } from 'lodash/fp';
import siteConfig from 'propera/siteConfig';
import {
  Dropdown,
  Input,
  Select,
  Option,
  FormItem,
  Alert,
  Icon,
  Button,
  Checkbox,
  RadioGroup,
  Tooltip
} from '@freightos/design-system';

import { SECTIONS, GOODS_READY_RANGES } from 'slimSearch/constants';
import {
  StyledCategoryCard,
  MobileClose,
  ViewHolder,
  CategoryOverlayWrapper,
  mobileOverLayStyle,
  FieldError,
  StyledFormItem,
  MobileSectionTitle,
  MobileSectionSubTitle,
  MobileSectionHeader,
  MobilePortalContainer,
  MobilePortalMain,
  MobilePortalFooter
} from 'slimSearch/sharedStyles';
import { updateField } from 'slimSearch/actions';
import { openArticle } from 'utils';
import { getEventDatesTexts } from 'slimSearch/utils';
import { moneyFormat } from 'utils';
import { isMobileSelector } from 'slimSearch/selectors';
import Category from 'slimSearch/components/Category';
import SectionFooter from 'slimSearch/components/sections/SectionFooter';

import { t } from 'utils/translationProvider';

export const Goods = ({
  goods,
  load,
  updateField,
  activeSection,
  supportedCurrencies,
  isMobile,
  isIPhone,
  dropdownHeight,
  pristine,
  mode
}) => {
  const getSubtitle = () => {
    let title = '';
    const dateRangeText = getEventDatesTexts(goods.pickupEvent.goodsReadyRange);
    if (goods.goodsValue.value) {
      title += `${moneyFormat({
        amount: goods.goodsValue.value,
        currency: goods.goodsValue.currency
      })} | `;
    }

    if (dateRangeText) {
      title += dateRangeText;
    }

    return title || t('Tell us about your goods', 'Tell us about your goods');
  };

  // @TODO: add holydays
  const alertMessage = () => {
    if (goods.pickupEvent.goodsReadyRange === GOODS_READY_RANGES.READY_IN_MORE_THAN_TWO_WEEKS) {
      return (
        <span>
          Please note, rates on Freightos.com are updated every two weeks. If your goods will only
          be ready and gated-in after the expiry date (found on each Quote tile), your rates will be
          different from when selected.
        </span>
      );
    }

    return (
      <span>
        Please note the expiry date on each Quote tile. Rates are subject to change if your goods
        are not gated-in before that date.{' '}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <Button type="text" onClick={() => openArticle('51475-how-do-i-know-if-a-quote-is-valid')}>
          Learn more.
        </Button>
      </span>
    );
  };

  const goodsReadyOptions = [
    {
      label: t('Yes, my goods are ready', 'Yes, my goods are ready'),
      value: GOODS_READY_RANGES.READY_NOW
    },
    {
      label: t('Will be ready within two weeks', 'Will be ready within two weeks'),
      value: GOODS_READY_RANGES.READY_IN_TWO_WEEKS
    },
    {
      label: t('Will be ready in more than two weeks', 'Will be ready in more than two weeks'),
      value: GOODS_READY_RANGES.READY_IN_MORE_THAN_TWO_WEEKS
    }
  ];

  if (isMobile && mode !== 'search' && mode !== 'services-results') {
    return <div>{getSubtitle()}</div>;
  }

  const Header = (
    <>
      {isMobile && (
        <MobileClose data-test-id={`${SECTIONS.GOODS}-mobile-close`}>
          <Icon
            type="close"
            onClick={() => updateField('activeSection', null, { section: activeSection })}
          />
        </MobileClose>
      )}

      {isMobile ? (
        <>
          <MobileSectionTitle>{upperFirst(SECTIONS.GOODS)}</MobileSectionTitle>
          <MobileSectionSubTitle>
            {t('search/ss_goods_header_text', 'Tell us about your goods')} {/*<Icon*/}
            {/*  type="help-circled"*/}
            {/*  onClick={() => openArticle('50584-what-is-my-goods-ready-date')}*/}
            {/*/>*/}
          </MobileSectionSubTitle>
        </>
      ) : (
        <h3 data-test-id={`${SECTIONS.GOODS}-section-title`}>
          {t('search/ss_goods_header_text', 'Tell us about your goods')} {/*<Icon*/}
          {/*  type="help-circled"*/}
          {/*  size="tiny"*/}
          {/*  onClick={() => openArticle('50584-what-is-my-goods-ready-date')}*/}
          {/*/>*/}
        </h3>
      )}
    </>
  );
  const Content = (
    <CategoryOverlayWrapper isMobile={isMobile} isIPhone={isIPhone} hasFooter>
      <StyledFormItem
        colon={false}
        label={
          <span>
            {t('Goods value', 'Goods value')}
            <Tooltip
              title={t(
                'You can find your goods value on your commercial invoice. ',
                'You can find your goods value on your commercial invoice.'
              )}
            >
              <Icon type="help-circled" size="tiny" />
            </Tooltip>
          </span>
        }
      >
        <Input
          data-test-id={`${SECTIONS.GOODS}-section-value`}
          value={toString(goods.goodsValue?.value)}
          size={isMobile ? 'large' : 'default'}
          type="number"
          step={50}
          min={1}
          max={500000}
          onChange={(event) => {
            updateField('quote.goods.goodsValue.value', parseFloat(event.target.value));
          }}
          addonAfter={
            <Select
              style={{ width: 64 }}
              defaultValue={goods.goodsValue.currency}
              data-test-id={`${SECTIONS.GOODS}-section-value-currency`}
              onChange={(value) => {
                updateField('quote.goods.goodsValue.currency', value);
              }}
            >
              {Object.keys(supportedCurrencies).map((currency) => (
                <Option
                  key={currency}
                  value={currency}
                  data-test-id={`${SECTIONS.GOODS}-section-value-currency-${currency}`}
                >
                  {currency}
                </Option>
              ))}
            </Select>
          }
        />
        {pristine === false && !isFinite(goods.goodsValue?.value) && (
          <FieldError>Required&nbsp;</FieldError>
        )}
      </StyledFormItem>

      <FormItem colon={false} label={null}>
        <Checkbox
          size={isMobile ? 'large' : 'default'}
          defaultChecked={load.hazardous}
          data-test-id={`${SECTIONS.GOODS}-section-hazardous`}
          onChange={(event) => {
            updateField(`quote.load.hazardous`, event.target.checked);
          }}
        >
          {t('search/ss_load_hazardous_label_text', 'Shipment contains hazardous goods')}
        </Checkbox>
      </FormItem>

      {load.hazardous && (
        <>
          <Alert
            data-test-id={`${SECTIONS.GOODS}-section-hazardous-alert`}
            type="warning"
            closable={false}
            message={
              <span>
                Currently, our forwarders only handle hazardous shipments for items containing{' '}
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                lithium ion batteries. They don't support other hazardous shipments.{' '}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                {/*<a href="">Learn more</a>.*/}
              </span>
            }
          />
          <br />
        </>
      )}

      <StyledFormItem
        colon={false}
        label={
          <span>
            {t('Are your goods ready?', 'Are your goods ready?')}{' '}
            <Icon
              type="help-circled"
              size="tiny"
              onClick={() => openArticle('50584-what-is-my-goods-ready-date')}
            />
          </span>
        }
      >
        {isMobile ? (
          <StyledRadioGroup
            card
            {...(goods.pickupEvent.goodsReadyRange
              ? { value: goods.pickupEvent.goodsReadyRange }
              : null)}
            onChange={(event) =>
              updateField('quote.goods.pickupEvent.goodsReadyRange', event.target.value)
            }
            options={goodsReadyOptions}
            data-test-id={`${SECTIONS.GOODS}-section-timeframe`}
            vertical
          />
        ) : (
          <Select
            {...(goods.pickupEvent.goodsReadyRange
              ? { value: goods.pickupEvent.goodsReadyRange }
              : null)}
            size={isMobile ? 'large' : 'default'}
            placeholder="Select timeframe"
            data-test-id={`${SECTIONS.GOODS}-section-timeframe`}
            onChange={(range) => updateField('quote.goods.pickupEvent.goodsReadyRange', range)}
          >
            {goodsReadyOptions.map((option) => (
              <Option
                value={option.value}
                key={option.value}
                data-test-id={`${SECTIONS.GOODS}-section-timeframe-${option.value}`}
              >
                {option.label}
              </Option>
            ))}
          </Select>
        )}

        {pristine === false && isEmpty(goods.pickupEvent?.goodsReadyRange) && (
          <FieldError>Required&nbsp;</FieldError>
        )}
      </StyledFormItem>
      {goods.pickupEvent?.goodsReadyRange && alertMessage() && (
        <>
          {!isMobile && <br />}
          <Alert
            data-test-id={`${SECTIONS.GOODS}-section-range-alert`}
            type="info"
            closable={false}
            message={alertMessage()}
          />
          {!isMobile && <br />}
        </>
      )}
    </CategoryOverlayWrapper>
  );
  const Footer = (
    <SectionFooter
      onDone={() =>
        updateField('activeSection', null, {
          section: activeSection
        })
      }
    />
  );

  if (isMobile && activeSection === SECTIONS.GOODS) {
    return ReactDOM.createPortal(
      <MobilePortalContainer>
        <MobilePortalMain>
          <MobileSectionHeader>{Header}</MobileSectionHeader>
          {Content}
          {/* Please dont remove the iphone thing bellow. Safari ignore margin in this case */}
          {isIPhone ? (
            <>
              <br />
              <br />
              <br />
              <br />
            </>
          ) : null}
        </MobilePortalMain>
        <MobilePortalFooter>{Footer}</MobilePortalFooter>
      </MobilePortalContainer>,
      document.body
    );
  }

  return (
    <StyledDropdown
      {...(isMobile
        ? {
            transitionName: null
          }
        : { getPopupContainer: (trigger) => trigger.parentElement })}
      data-test-id={`${SECTIONS.GOODS}-dropdown`}
      placement="bottomRight"
      isMobile={isMobile}
      noMaxHeight
      style={{ position: 'relative' }}
      overlayStyle={isMobile ? mobileOverLayStyle : ''}
      visible={activeSection === SECTIONS.GOODS}
      overlay={
        <StyledCategoryCard width={300} height={dropdownHeight} isMobile={isMobile}>
          {
            <OutsideClickHandler
              disabled={activeSection !== SECTIONS.GOODS}
              onOutsideClick={(event) => {
                if (
                  event.target.matches(
                    '.ant-dropdown-menu, .ant-select-dropdown *, .ant-dropdown-menu *'
                  )
                  // event?.target?.className.includes('ant-select-dropdown-menu-item') ||
                  // event?.target?.className.includes('ant-dropdown-menu')
                ) {
                  event.stopPropagation();
                  event.stopImmediatePropagation();
                } else {
                  updateField('activeSection', null, { section: activeSection });
                }
              }}
            >
              <ViewHolder isMobile={isMobile} dropdownHeight={dropdownHeight}>
                {Header}
                {Content}
                {Footer}
              </ViewHolder>
            </OutsideClickHandler>
          }
        </StyledCategoryCard>
      }
      size="large"
      trigger={['click']}
    >
      <Category sectionTitle="Goods" subTitle={getSubtitle()} section={SECTIONS.GOODS} />
    </StyledDropdown>
  );
};

const StyledDropdown = styled(Dropdown)`
  &::before {
    all: unset;
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  .ant-radio-wrapper:last-child {
    margin-bottom: 0;
  }
`;

const mapStateToProps = (store) => ({
  goods: store.search.quote.goods,
  pristine: store.search?.pristine?.goods,
  load: store.search.quote.load,
  activeSection: store.search.activeSection,
  dropdownHeight: store.search.dropdownHeight,
  supportedCurrencies: siteConfig.getSupportedCurrencies(store),
  isMobile: isMobileSelector(store),
  isIPhone: store.layout.device.model === 'iPhone' && store.layout.device.os === 'iOS'
});

export default connect(mapStateToProps, {
  updateField
})(Goods);
