import { template } from 'lodash/fp';

export const kebabize = (str) =>
  str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase());

export const createAsyncAction = (type) => ({
  PENDING: `${type}_PENDING`,
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
  type
});

export const createToggleAction = (type) => ({
  ON: `${type}_ON`,
  OFF: `${type}_OFF`
});

/**
 *
 * @returns tld = top level domain
 */
export const getTLD = (uri = undefined) => {
  const url = uri || document.location.origin;

  if (url.includes('freightos.cn')) {
    return 'freightos.cn';
  }

  if (url.includes('test-') || url.includes('tradeos-test1.appspot.com')) {
    return 'test-freightos.com';
  }

  return 'freightos.com';
};

export const endpointConstructor = (tpl, params) => template(tpl)(params);

export const getDefaultValue = (param) => {
  const parsedUrl = new URL(window.location.href);

  return parsedUrl.searchParams.get(param);
};

export const paramObjectToUri = (paramObject = {}) => {
  const results = Object.entries(paramObject).reduce(
    (acc, value) => `${acc}${value[0]}=${value[1]}&`,
    ''
  );
  return encodeURI(results.slice(0, -1));
};

//@todo there is an action for that in propera
export const openArticle = (articleId) => {
  window.supportHeroWidget.show({ article: articleId });
};

export const moneyFormat = ({ amount, locale = 'en-US', currency = 'USD' }) =>
  Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0
  })
    .format(amount)
    .replace(/(\.|,)00$/g, '');

export const randomDate = (start, end) => {
  return new Date(+start + Math.random() * (end - start)).toISOString();
};
