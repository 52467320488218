import { Content, Header, Layout, Sider, Result, Button } from '@freightos/design-system';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'microfronts-redux';
import { resetAllFilters } from 'results/results.actions';
import { FLinearLoader } from 'propera/components/loaders';
import React, { useEffect } from 'react';
import Filters from 'results/components/Filters';
import SortOptions from 'results/components/SortOptions';
import FilterDrawerWrapper from 'results/components/FilterDrawerWrapper';
import ResultCountTitle from 'results/components/ResultsCountTitle';
import { initResultsPage } from 'results/results.actions';
import { getRfqKeyFromUrl } from 'results/results.utils';
import {
  resultsCountSelector,
  servicesReferrerSelector,
  selectQuotesIdsList,
  loaderSelector,
  filteredResultsSelector
} from 'results/results.selectors';
import SearchCategories from 'slimSearch/components/SearchCategories';
import RecommendedServices from 'slimSearch/components/services/RecommendedServices';
import SearchStepProgress from 'slimSearch/components/services/SearchStepProgress';
import styled from 'styled-components/macro';
import { t } from 'utils/translationProvider';
import { ReactComponent as NoCoverageImage } from 'slimSearch/img/no-coverage.svg';
import { ReactComponent as Loading } from 'slimSearch/img/loading-results.svg';
import { ReactComponent as NoFilteredResults } from 'slimSearch/img/no-filters.svg';
import { ResultsScreenValues, ANALYTICS_EVENT_NAME } from 'slimSearch/constants';
import { searchAnalytics } from 'slimSearch/actions';
import { fdsColorPrimaryGray20 } from '@freightos/design-system/dist/tokens';

export const ResultViewComponent = ({ children }) => {
  const dispatch = useDispatch();

  const { resultsCount = 0 } = useSelector(resultsCountSelector);
  const isLoading = useSelector(loaderSelector);
  const servicesReferrer = useSelector(servicesReferrerSelector);
  const showRecommendedServices = useSelector((state) => get(state, 'search.servicesModalVisible'));
  const noCoverage = useSelector((state) => get(state, 'results.noCoverage'));
  const idsList = useSelector(selectQuotesIdsList);
  const filteredResultsCount = useSelector(filteredResultsSelector);

  useEffect(() => {
    // if not services referrer is meaning we are coming with refresh
    if (!servicesReferrer) {
      const rfqKey = getRfqKeyFromUrl();
      if (rfqKey) {
        dispatch(initResultsPage({ rfqKey }));
      }
    }
    dispatch(
      searchAnalytics({
        name: ANALYTICS_EVENT_NAME.RESULTS_PAGE_LOADED,
        data: {
          rfqKey: getRfqKeyFromUrl()
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainResultContainer id="results-view">
      <FilterDrawerWrapper />
      <FLinearLoader isLoading={isLoading} />

      <SearchStepProgressContainer>
        <SearchStepProgress active="results" />
      </SearchStepProgressContainer>

      {!showRecommendedServices ? (
        <StyledSearchCategories mode="results" />
      ) : (
        <RecommendedServices mode="results">
          <SearchCategories mode="services-results" />
        </RecommendedServices>
      )}

      {noCoverage ? (
        <Result
          icon={<NoCoverageImage />}
          subTitle={t(
            'noCoverage/subTitle',
            "We don't currently cover the route you're looking for. Try searching a different lane."
          )}
          title={t('noCoverage/title', 'No coverage')}
        />
      ) : (
        <StyledLayout className="results-layout">
          {resultsCount > 0 && (
            <StyledHeader>
              <ResultCountTitle />
              {filteredResultsCount.length === 0 ? null : <SortOptions />}
            </StyledHeader>
          )}

          <StyledResultsLayout>
            {resultsCount === 0 ? (
              <Result
                icon={<StyledLoading style={{ width: '200px' }} />}
                subTitle={t('resultsPage/loadingQuotes', 'Almost there')}
                title={t('resultsPage/loadingQuotes/title', 'Loading...')}
              />
            ) : (
              <React.Fragment>
                <StyledSided width={'240px'} theme="light">
                  <Filters />
                </StyledSided>
                <StyledContent>
                  {filteredResultsCount.length === 0 ? (
                    <Result
                      icon={<StyledNoFilteredResults style={{ width: '200px', height: '200px' }} />}
                      subTitle={t(
                        'resultsPage/filtersEmptySubtitle',
                        'Please adjust the filter and try again. '
                      )}
                      title={t('resultsPage/filtersEmptyTitle', 'No results found for your search')}
                      extra={
                        <StyledResetFiltersButton
                          type="primary"
                          onClick={() => dispatch(resetAllFilters())}
                        >
                          {t('filters/clearAllFilters', 'Clear all filters')}
                        </StyledResetFiltersButton>
                      }
                    />
                  ) : (
                    children
                  )}
                  {idsList.main.length + idsList.others.length > 0 && (
                    <DisclaimerLabel>
                      <div>
                        {t(
                          'propera/AllCarrierMsg',
                          'All carrier or other third-party names and logos are the trademarks of their respective owners. No endorsement of Freightos or of the Sellers is implied.'
                        )}
                      </div>
                    </DisclaimerLabel>
                  )}
                </StyledContent>
              </React.Fragment>
            )}
          </StyledResultsLayout>
        </StyledLayout>
      )}
    </MainResultContainer>
  );
};

const StyledSearchCategories = styled(SearchCategories)`
  //border:1px solid green;
`;

const MainResultContainer = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  @media (max-width: ${ResultsScreenValues.tablet}px) {
    padding-top: 100px;
    .linear-loader.success {
      top: 60px; // rught under the header
    }
  }
`;

const SearchStepProgressContainer = styled.div`
  margin: 50px auto 60px;
  width: 60%;
  @media (max-width: ${ResultsScreenValues.tablet - 1}px) {
    display: none;
  }
`;

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
  //border: 1px solid red;
  @media (min-width: ${ResultsScreenValues.desktop}px) {
    width: ${ResultsScreenValues.desktopContent}px;
  }
  @media (max-width: ${ResultsScreenValues.desktop - 1}px) {
    width: 90vw;
  }
  @media (max-width: ${ResultsScreenValues.desktop - 1}px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const StyledLayout = styled(Layout)`
  &.ant-layout {
    background: white;
  }
  display: flex;
  align-items: center;
  background: white;
  margin: 15px auto 0;
  border-top: 1px solid ${fdsColorPrimaryGray20};
  &.results-layout.ant-layout ${StyledHeader}.ant-layout-header {
    box-shadow: none;
    line-height: initial;
    height: auto;
    margin-top: 15px;
    margin-bottom: 6px;
    position: relative;
    z-index: 0;
  }
`;

const StyledResultsLayout = styled(Layout)`
  background: white;
  &.ant-layout {
    background: white;
  }

  @media (max-width: ${ResultsScreenValues.desktop - 1}px) {
    width: calc(90vw + 20px);
  }

  @media (min-width: ${ResultsScreenValues.desktop}px) {
    width: ${ResultsScreenValues.desktopContent + 10 + 'px'};
    margin-left: 10px;
  }
  //border:1px solid black;
`;

const StyledSided = styled(Sider)`
  background-color: white;
  width: 240px;
  display: none;
  @media (min-width: ${ResultsScreenValues.desktop}px) {
    display: block;
  }
`;

const StyledContent = styled(Content)`
  &.ant-content {
    background: white;
  }
  @media (min-width: ${ResultsScreenValues.desktop}px) {
    padding: 18px 10px 0 18px;
  }
  padding: 18px 12px 0 12px;
  z-index: 0;
`;

const DisclaimerLabel = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
  div {
    width: 60%;
    line-height: 1.5;
    text-align: center;
  }
`;

const StyledLoading = styled(Loading)`
  width: 200px;
`;

const StyledNoFilteredResults = styled(NoFilteredResults)`
  width: 200px;
  height: 200px;
  margin: 0 0 -24px 0; // compensate for current image size/proportions
`;

const StyledResetFiltersButton = styled(Button)`
  display: inline !important; // Yes. I have to at this point. Dont tell mom.
`;

export default ResultViewComponent;
