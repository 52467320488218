import React, { useState } from 'react';
import moment from 'moment';
import { getOr, isEmpty } from 'lodash/fp';
import { connect } from 'microfronts-redux';
import siteConfig from 'propera/siteConfig';
import styled from 'styled-components/macro';
import { Alert } from '@freightos/design-system';

import { fdsComponentSpacingLg } from '@freightos/design-system/dist/tokens';
import { SECTIONS } from '../constants';

export const HolidayAlert = ({ originLocation, holidays, shouldShow }) => {
  const [holidaysAlert, hideHolidaysAlert] = useState(null);

  if (holidaysAlert === false && holidays.length === 0) {
    return null;
  }

  if (!shouldShow) {
    return null;
  }

  return holidays.map((item) => {
    if (
      !item.holiday?.holidayNotification ||
      !item.holiday.conditionFieldValue.includes(originLocation?.countryID?.value)
    ) {
      return null;
    }

    const startDate = moment(getOr(null, 'datesRangeStart', item.holiday)).startOf('day');
    const endDate = moment(getOr(null, 'datesRangeEnd', item.holiday)).endOf('day');
    const deprecatedDatesValueIsSet = !isEmpty(getOr('', 'dates', item.holiday));
    const isInRange = moment().isBetween(startDate, endDate);

    if (deprecatedDatesValueIsSet || (startDate === null && endDate === null) || !isInRange) {
      return null;
    }

    return (
      <StyledHolidayAlert
        onClose={() => hideHolidaysAlert(true)}
        key={item.holiday?.holidayNotification}
        type="warning"
        message={item.holiday?.holidayNotification}
      />
    );
  });
};

const StyledHolidayAlert = styled(Alert)`
  margin-bottom: ${fdsComponentSpacingLg};
`;

const mapStateToProps = (store) => ({
  originLocation: store.search.quote.origin.originLocation,
  holidays: siteConfig.getHolidays(store),
  shouldShow: store.search.touched[SECTIONS.ORIGIN] && !store.search.pristine[SECTIONS.ORIGIN]
});

export default connect(mapStateToProps)(HolidayAlert);
