import { t } from 'utils/translationProvider';
import { getTLD, getDefaultValue } from 'utils';
import { keyBy } from 'lodash/fp';

export const PACKAGE_NAME = 'SlimSearch';

export const PAGE_ROUTE = 'search';
export const CUSTOM_QUOTE_ROUTE = `${PAGE_ROUTE}/custom-quote`;

export const PAGE_WIDTH = 1200;

export const ENDPOINTS = {
  search: '/api/open-freight/quoting/quotes/search',
  searchAgainKeepRfq: '/api/open-freight/quoting/quotes/search?originalRfqKey=<%= originalRfqKey%>',
  updateRfq: '/api/open-freight/quoting/quotes/<%= rfqKey%>/search',
  fetchQuotes: `/api/open-freight/quoting/quotes/<%= rfqId%>/poll?cursor=<%= cursor%>`,
  singleQuoteSummary: `/api/open-freight/quoting/quotes/<%= quoteId%>/summary`,
  rfqSummary: `/api/open-freight/quoting/quoteRequest/<%= rfqId%>`,
  rfqSummaryWithoutPulling: `/api/open-freight/quoting/quoteRequest`,
  addressesAutocomplete: `https://flux.${getTLD()}/api/v1/geocoder/autocomplete?searchTerm=<%= searchTerm %>&filters=country:<%= country %>&language=en`,
  portsAutocomplete: `/servlet/geoPlaces?country=<%= country %>&types=seaports,airports&isQuotingContext=true&searchTerm=<%= searchTerm %>`,
  brandLocations: `https://flux.${getTLD()}/api/v1/locations?availableInLcl=true&brand=AMZ&brand=UPS&brand=shipbob`,
  brandCountries: `https://flux.${getTLD()}/api/v1/locations/country?availableInLcl=true`,
  vendors: `/api/open-freight/parties/sellers/<%= vendorId%>`,
  addressBook: `https://business-configuration.${getTLD()}/address/<%= businessKey %>?country=<%= country %>`,
  addressBookSingleAddress: `https://business-configuration.${getTLD()}/address/single/<%= id %>`
};

export const TRANSPORT_SERVICE = {
  DOOR_TO_DOOR: 'Door-to-door',
  DOOR_TO_PORT: 'Door-to-port',
  PORT_TO_DOOR: 'Port-to-door',
  PORT_TO_PORT: 'Port-to-port'
};

export const ResultsScreenValues = {
  desktop: 1280,
  desktopContent: 1200,
  midDesktop: 1050,
  tablet: 768,
  mobile: 375,
  minPadding: 50
};
export const MIN_CHARS_TO_START_SEARCH = 2;

export const pollingDelay = 1000;

export const ISF_BOND = 75;

export const SINGLE_ENTRY_BOND = {
  minPrice: 50,
  percentOfShipmentValue: 0.005
};

export const WIDGET_SEARCH_WITH_URL = document.location.pathname.includes('search-widget');
export const ldFlags = {
  SLIM_SEARCH_RESULTS: 'slim-search-results',
  ADDRESS_BOOK_ADDRESSES_IN_SEARCH: 'address-book-addresses-in-search'
};
export const IS_SHIPBOB =
  document.location.origin.includes('shipbob') || getDefaultValue('brand') === 'shipbob';

export const DATE_FORMAT = 'MMM DD, YYYY';

export const ANALYTICS_EVENT_NAME = {
  SS_COMPONENT_VIEWED: 'ss_component_viewed',
  SEARCH_PAGE_CONTENT_BOX_1_CLICKED: 'search_page_content_box_1_clicked',
  SEARCH_PAGE_CONTENT_BOX_2_CLICKED: 'search_page_content_box_2_clicked',
  SEARCH_PAGE_CONTENT_BOX_3_CLICKED: 'search_page_content_box_3_clicked',
  SS_DESTINATION_BOX_INPUT_CLICKED: 'ss_destination_box_input_clicked',
  SS_ORIGIN_BOX_INPUT_CLICKED: 'ss_origin_box_input_clicked',
  SS_LOAD_BOX_INPUT_CLICKED: 'ss_load_box_input_clicked',
  SS_GOODS_BOX_INPUT_CLICKED: 'ss_goods_box_input_clicked',
  SS_SEARCH_BUTTON_SUCCESS: 'ss_search_button_success',
  SS_SEARCH_BUTTON_FAIL: 'ss_search_button_fail',
  SS_ORIGIN_BOX_INPUT_COMPLETED: 'ss_origin_box_input_completed',
  SS_DESTINATION_BOX_INPUT_COMPLETED: 'ss_destination_box_input_completed',
  SS_LOAD_BOX_INPUT_COMPLETED: 'ss_load_box_input_completed',
  SS_GOODS_BOX_INPUT_COMPLETED: 'ss_goods_box_input_completed',
  RS_COMPONENT_OPENED: 'rs_component_opened',
  RESULTS_OLD_RFQ_LINK_REDIRECT: 'results_old_rfq_link_redirect',
  SEARCH_RESULTS_FETCHED: 'search_results_fetched',
  SEARCH_RESULTS_FETCHED_COMPLETED_WITH_NO_QUOTES:
    'search_results_fetched_completed_with_no_quotes',
  SEARCH_RESULTS_FETCHED_COMPLETED_WITH_TIMEOUT: 'search_results_fetched_completed_with_timeout',
  SEARCH_RESULTS_FETCHED_CLEAN: 'search_results_fetched_clean',
  SEARCH_RESULTS_PARTIALLY_FETCHED: 'search_results_partially_fetched',
  RESULTS_FILTER_MODES_SELECTED: 'results_filter_modes_selected',
  RESULTS_FILTER_SELLER_SELECTED: 'results_filter_seller_selected',
  RESULTS_EDIT_SEARCH_BUTTON_CLICKED: 'results_edit_search_button_clicked',
  RESULTS_SORTED: 'results_sorted',
  RESULTS_PAGE_LOADED: 'results_page_loaded',
  BOOK_BUTTON_CLICKED: 'book_button_clicked',
  SEARCH_EXECUTED: 'search_executed',
  CUSTOM_QUOTE_REQUESTED: 'custom_quote_requested',
  CUSTOM_QUOTE_RESULT_PAGE_PRESENTED: 'custom_quote_result_page_presented'
};

export const MOST_COMMON_ORIGIN_COUNTRIES = ['CN', 'US', 'IN', 'TW', 'VN', 'GB'];
export const MOST_COMMON_DESTINATION_COUNTRIES = ['US', 'GB', 'CA', 'DE', 'AU'];

export const SECTIONS = {
  ORIGIN: 'origin',
  DESTINATION: 'destination',
  LOAD: 'load',
  GOODS: 'goods'
};

export const FULFILMENT_CENTER_COMPANIES = {
  AMZ: { key: 'AMZ', label: t('amazon', 'Amazon') },
  SHIPBOB: { key: 'SHIPBOB', label: t('shipbob', 'Shipbob') }
};

export const FULFILMENT_CENTER_BRAND_TO_KEY = {
  AMAZON: 'AMZ',
  SHIPBOB: 'SHIPBOB',
  UPS: 'UPS'
};

export const ADDRESS_TYPES = {
  PORT: { label: t('Port/Airport', 'Port/Airport'), value: 'port', key: 'port', icon: 'port' },
  FACTORY_WAREHOUSE: {
    label: t('Factory/Warehouse', 'Factory/Warehouse'),
    value: 'warehouse',
    key: 'warehouse',
    icon: 'warehouse'
  },
  BUSINESS_ADDRESS: {
    label: t('Business address', 'Business address'),
    value: 'business',
    key: 'business',
    icon: 'office'
  },
  ADDRESS: {
    label: t('Residential address', 'Residential address'),
    value: 'residential',
    key: 'residential',
    icon: 'residential'
  },
  FULFILMENT_CENTER: {
    label: t('Fulfillment center', 'Fulfillment center'),
    value: 'fulfillment',
    key: 'fulfillment',
    icon: 'fulfillment-center'
  },
  LAST_MILE_DELIVERY: {
    label: t(
      'Last mile delivery warehousing (UPS FBA)',
      'Last mile delivery warehousing (UPS FBA)'
    ),
    value: 'lastMileDelivery',
    key: 'lastMileDelivery',
    icon: 'last-mile-delivery'
  }
};

export const ORIGIN_DESTINATION_TYPES = {
  ORIGIN: {
    PORT: ADDRESS_TYPES.PORT,
    FACTORY_WAREHOUSE: { ...ADDRESS_TYPES.FACTORY_WAREHOUSE, icon: 'factory' },
    BUSINESS_ADDRESS: ADDRESS_TYPES.BUSINESS_ADDRESS,
    ADDRESS: ADDRESS_TYPES.ADDRESS
  },
  DESTINATION: {
    PORT: ADDRESS_TYPES.PORT,
    FACTORY_WAREHOUSE: { ...ADDRESS_TYPES.FACTORY_WAREHOUSE, label: t('Warehouse', 'Warehouse') },
    FULFILMENT_CENTER: ADDRESS_TYPES.FULFILMENT_CENTER,
    BUSINESS_ADDRESS: ADDRESS_TYPES.BUSINESS_ADDRESS,
    ADDRESS: ADDRESS_TYPES.ADDRESS,
    LAST_MILE_DELIVERY: ADDRESS_TYPES.LAST_MILE_DELIVERY
  }
};

export const ADDRESS_BASED_LOCATION_TYPES = [
  ADDRESS_TYPES.FACTORY_WAREHOUSE.value,
  ADDRESS_TYPES.ADDRESS.value,
  ADDRESS_TYPES.BUSINESS_ADDRESS.value
];

export const LOAD_TYPES = {
  CONTAINER: {
    label: t('Containers', 'Containers'),
    value: 'container'
  },
  LOOSE_CARGO: {
    label: t('Loose Cargo', 'Loose Cargo'),
    value: 'looseCargo'
  },
  TOTAL: {
    label: t('Total Shipment', 'Total Shipment'),
    value: 'total'
  },
  PALLETS: {
    label: t('Pallets', 'Pallets'),
    value: 'pallets'
  },
  BOX: {
    label: t('Boxes/Crates', 'Boxes/Crates'),
    value: 'boxes'
  }
};

export const CONTAINER_TYPES = [
  {
    label: "20'",
    value: 'container20'
  },
  {
    label: "40'",
    value: 'container40'
  },
  {
    label: "40'HC",
    value: 'container40HC'
  },
  {
    label: "45'HC",
    value: 'container45HC'
  }
];

export const CONTAINER_TYPES_VALUES = keyBy('value', CONTAINER_TYPES);

export const PALLET_TYPES = [
  {
    label: '48" × 40"',
    value: 'pallet_48_40'
  },
  {
    label: '120 × 80CM (EUR1)',
    value: 'pallet_EUR1'
  },
  {
    label: '120 × 100CM (EUR2)',
    value: 'pallet_EUR2'
  },
  {
    label: t('Other', 'Pallets (non specified size)'),
    value: 'pallets'
  }
];
export const CUBIC_FEET_IN_METERS = 35.3174;

export const DIMENSION_UNITS = {
  CM: 'cm',
  INCH: 'in'
};
export const WEIGHT_UNITS = {
  KG: 'kg',
  LB: 'lb'
};
export const VOLUME_UNITS = {
  CBM: 'cbm',
  CFT: 'cft'
};

export const ACCESSORIALS_SCOPES = {
  GENERAL: 'General',
  PICKUP: 'Pickup',
  DELIVERY: 'Delivery'
};

export const ACCESSORIALS_NAMES = {
  LIFT_GATE: 'liftGate',
  RESIDENTIAL_BASIC_SERVICES: 'residentialBasicService',
  HAZARDOUS: 'Hazardous'
};

export const OPEN_FREIGHT_LOCATION_TYPES = {
  ADDRESS: 'address',
  SEAPORT: 'seaport',
  AIRPORT: 'airport'
};

export const FCL_LOAD_DEFAULTS = {
  quantity: 1,
  packagingType: 'container40',
  overWeightIndicator: false
};

export const TOTAL_LOAD_DEFAULTS = {
  quantity: 1,
  volume: {
    value: ''
  },
  weight: {
    value: ''
  }
};

export const LCL_LOAD_DEFAULTS = {
  boxes: {
    quantity: 1,
    packagingType: 'boxes',
    weight: {
      value: ''
    },
    packageDimensions: {
      width: {
        value: ''
      },
      length: {
        value: ''
      },
      height: {
        value: ''
      }
    }
  },
  pallets: {
    quantity: 1,
    packagingType: 'pallets',
    weight: {
      value: ''
    },
    packageDimensions: {
      width: {
        value: ''
      },
      length: {
        value: ''
      },
      height: {
        value: ''
      }
    }
  },
  pallet_48_40: {
    quantity: 1,
    packagingType: 'pallet_48_40',
    weight: {
      value: ''
    },
    packageDimensions: {
      height: {
        value: ''
      }
    }
  },
  pallet_EUR1: {
    quantity: 1,
    packagingType: 'pallet_EUR1',
    weight: {
      value: ''
    },
    packageDimensions: {
      height: {
        value: ''
      }
    }
  },
  pallet_EUR2: {
    quantity: 1,
    packagingType: 'pallet_EUR2',
    weight: {
      value: ''
    },
    packageDimensions: {
      height: {
        value: ''
      }
    }
  }
};

export const DEFAULT_PALLET_SIZES = {
  pallet_48_40: {
    cm: {
      width: 100,
      length: 120
    },
    in: {
      width: 40,
      length: 48
    }
  },
  pallet_EUR1: {
    cm: {
      width: 80,
      length: 120
    },
    in: {
      width: 31.5,
      length: 47.24
    }
  },
  pallet_EUR2: {
    cm: {
      width: 120,
      length: 100
    },
    in: {
      width: 47.24,
      length: 39.37
    }
  }
};

export const GOODS_READY_RANGES = {
  READY_NOW: 'ready-now',
  READY_IN_TWO_WEEKS: 'ready-in-two-weeks',
  READY_IN_MORE_THAN_TWO_WEEKS: 'ready-more-than-two-weeks'
};

export const LOAD_VALIDATION = {
  totals: {
    weight: {
      min: {
        kg: 0.1,
        lb: 0.1
      },
      max: {
        kg: 34000,
        lb: 75000
      }
    },
    volume: {
      min: {
        cbm: 0.1,
        cft: 0.1
      },
      max: {
        cbm: 43,
        cft: 1500
      }
    }
  },
  weight: {
    pallets: {
      min: {
        kg: 0.1,
        lb: 0.1
      },
      max: {
        kg: 34000,
        lb: 75000
      }
    },
    boxes: {
      min: {
        kg: 0.1,
        lb: 0.1
      },
      max: {
        kg: 34000,
        lb: 75000
      }
    }
  },
  dimensions: {
    pallets: {
      min: {
        cm: 0.1,
        in: 0.4
      },
      max: {
        cm: 400,
        in: 158
      }
    },
    boxes: {
      min: {
        cm: 0.1,
        in: 0.1
      },
      max: {
        cm: 1400,
        in: 552
      }
    }
  },
  quantity: {
    pallets: {
      min: 1,
      max: 33
    },
    boxes: {
      min: 1,
      max: 999
    },
    quantity: {
      min: 1,
      max: 99
    }
  }
};
