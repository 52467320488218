import React from 'react';
import ReactDOM from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { connect } from 'microfronts-redux';
import {
  Col,
  Dropdown,
  FormItem,
  Row,
  Select,
  Option,
  Avatar,
  Icon,
  Alert,
  Popover
} from '@freightos/design-system';

import styled from 'styled-components/macro';
import { map, upperFirst, toUpper, isEmpty } from 'lodash/fp';
import { updateField, getPorts, getLocations, getAddressBookAddresses } from 'slimSearch/actions';
import { StyledCategoryCard, mobileOverLayStyle } from 'slimSearch/sharedStyles';

import { ADDRESS_TYPES, SECTIONS } from 'slimSearch/constants';
import { t, formatHTMLMessage } from 'utils/translationProvider';
import Category from 'slimSearch/components/Category';
import LocationSelect from 'slimSearch/components/sections/locations/LocationSelect';
import OriginDestinationMobile from 'slimSearch/components/sections/locations/mobile/OriginDestinationMobile';
import SectionFooter from 'slimSearch/components/sections/SectionFooter';
import { getCountryFlag, getLocationLabel, getLocationTypes } from 'slimSearch/utils';
import { isMobileSelector, shouldDisableAmazonSelector } from 'slimSearch/selectors';

export const OriginDestination = ({
  type,
  stateType,
  activeSection,
  updateField,
  isMobile,
  shouldDisableAmazon,
  touched,
  pristine,
  mode,
  getAddressBookAddresses,
  userSelectedAddress,
  userAddressesLoaded
}) => {
  const locationType = stateType[`${type}Location`];
  const isPort = locationType.locationTypeCode === ADDRESS_TYPES.PORT.value;
  const isFulfilmentCenter =
    locationType.locationTypeCode === ADDRESS_TYPES.FULFILMENT_CENTER.value;
  const isUps = locationType.locationTypeCode === ADDRESS_TYPES.LAST_MILE_DELIVERY.value;
  const country = locationType?.countryID?.value;
  const isStaticCenters = isUps || isFulfilmentCenter;

  React.useEffect(() => {
    if (type === SECTIONS.ORIGIN && !isStaticCenters && !isPort && country) {
      getAddressBookAddresses({ type: 'pickup', country });
    }
    if (type === SECTIONS.DESTINATION && !isStaticCenters && !isPort && country) {
      getAddressBookAddresses({ type: 'delivery', country });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, country]);

  const title =
    type === SECTIONS.ORIGIN
      ? t('search/ss_origin_header_text', 'Where are you shipping from?')
      : t('search/ss_destination_header_text', 'Where are you shipping to?');
  const LocationCodeTitle = () => {
    let title = locationType.locationCode;

    if (isPort) {
      title = toUpper(locationType.locationCode);
      return <span title={title}>{title}</span>;
    }

    if (isFulfilmentCenter) {
      title = locationType.ecommerceKey ? locationType.ecommerceKey : locationType.label;
      return <span title={title}>{title}</span>;
    }

    if (userAddressesLoaded) {
      return <span title={title}>{userSelectedAddress?.[type]?.company ?? title}</span>;
    } else {
      return (
        <span>
          <Icon type="loading" size="tiny" />
        </span>
      );
    }
  };
  const getSubtitle = () => {
    if (!touched) {
      return title;
    }

    const locationTypeLabel = getLocationLabel(type, locationType);
    if (locationType.locationTypeCode && !locationType.locationCode) {
      return (
        <>
          {locationTypeLabel} |{' '}
          {locationType.countryID.value ? (
            <StyledAvatar
              country={getCountryFlag(locationType.countryID.value)}
              type="flag"
              size="small"
            />
          ) : null}{' '}
        </>
      );
    }

    if (locationType.locationTypeCode && locationType.locationCode) {
      return (
        <>
          {locationTypeLabel} |{' '}
          {locationType.countryID.value ? (
            <StyledAvatar
              country={getCountryFlag(locationType.countryID.value)}
              type="flag"
              size="small"
            />
          ) : null}{' '}
          <LocationCodeTitle />
        </>
      );
    }

    return title;
  };

  if (isMobile && mode !== 'search' && mode !== 'services-results') {
    return <div>{getSubtitle()}</div>;
  }

  if (isMobile && activeSection === type) {
    return ReactDOM.createPortal(
      <OriginDestinationMobile
        isUps={isUps}
        type={type}
        locationType={locationType}
        title={title}
      />,
      document.body
    );
  }

  return (
    <StyledDropdown
      data-test-id={`${SECTIONS[type]}-dropdown`}
      {...(isMobile
        ? {
            transitionName: null
          }
        : { getPopupContainer: (trigger) => trigger.parentElement })}
      visible={activeSection === type}
      disabled={activeSection === type}
      overlayStyle={isMobile ? mobileOverLayStyle : ''}
      overlay={
        <StyledCategoryCard width={600}>
          <OutsideClickHandler
            disabled={activeSection !== type}
            onOutsideClick={(event) => {
              if (
                event.target.matches(
                  '.ant-dropdown-menu, .ant-select-dropdown *, .ant-dropdown-menu *'
                )
              ) {
                event.stopPropagation();
                event.stopImmediatePropagation();
              } else {
                updateField('activeSection', null, { section: activeSection });
              }
            }}
          >
            <h3>
              {title}{' '}
              <Popover
                getPopupContainer={(trigger) => trigger.parentElement}
                content={
                  <span data-test-id={`${type}-title-help`}>
                    {type === SECTIONS.ORIGIN
                      ? formatHTMLMessage(
                          'search/ss_origin_tooltip_text',
                          'Unsure which origin to select? <a target="_blank" href="https://resources.freightos.com/article/show/120717-how-do-i-know-which-origin-type-to-choose">Learn more</a>.'
                        )
                      : formatHTMLMessage(
                          'search/ss_destination_tooltip_text',
                          'Wondering which destination to choose? <a target="_blank" href="https://resources.freightos.com/article/show/121975-how-do-i-know-which-destination-type-to-choose">Learn more</a>.'
                        )}
                  </span>
                }
              >
                <Icon type="help-circled" size="tiny" />
              </Popover>
            </h3>
            <Row wrap={false} gutter={[8, 8]}>
              <Col span={24 / 3}>
                <FormItem colon={false} label={t('Type', 'Type')}>
                  <Select
                    disabled={getLocationTypes(type, shouldDisableAmazon).length === 1}
                    data-test-id={`${type}-type`}
                    value={locationType.locationTypeCode}
                    dropdownMatchSelectWidth={type === SECTIONS.ORIGIN}
                    onChange={(value) => {
                      updateField(`quote.${type}.${type}Location.locationTypeCode`, value);
                    }}
                  >
                    {map(
                      (item) => (
                        <Option key={item.value} value={item.value}>
                          <Icon type={item.icon} size="small" /> {item.label}
                        </Option>
                      ),
                      getLocationTypes(type, shouldDisableAmazon)
                    )}
                  </Select>
                </FormItem>
              </Col>

              <LocationSelect
                type={type}
                error={pristine === false && isEmpty(locationType.locationCode)}
              />
            </Row>
            {locationType.locationTypeCode === ADDRESS_TYPES.LAST_MILE_DELIVERY.value && (
              <>
                <br />
                <Alert
                  type="info"
                  closable={false}
                  message={formatHTMLMessage(
                    'search/ss_destination_last_mile_alert_text',
                    'If you have a courier for last-mile delivery to multiple fulfillment centers, you can ship your goods to the forwarder\'s warehouse. <a href="#" onClick="window.supportHeroWidget.show({article: \'117936-how-do-i-use-last-mile-delivery\'});">Learn more</a>'
                  )}
                />
              </>
            )}
            <br />
            <SectionFooter
              onDone={() =>
                updateField('activeSection', type === SECTIONS.ORIGIN ? 'destination' : 'load', {
                  section: type
                })
              }
            />
          </OutsideClickHandler>
        </StyledCategoryCard>
      }
      size="large"
      trigger={['click']}
    >
      <Category
        sectionTitle={t(upperFirst(type), upperFirst(type))}
        subTitle={getSubtitle()}
        section={type}
      />
    </StyledDropdown>
  );
};

const StyledDropdown = styled(Dropdown)`
  position: relative;
`;

const StyledAvatar = styled(Avatar)`
  &.ant-avatar-sm {
    width: 12px;
    height: 12px;
    line-height: 12px;
    top: -1px;
  }
`;

const mapStateToProps = (store, { type }) => ({
  stateType: store.search.quote[type],
  pristine: store.search.pristine[type],
  activeSection: store.search.activeSection,
  userAddressesLoaded: store.search.userAddressesLoaded,
  userSelectedAddress: store.search.quote?.userSelectedAddress,
  isMobile: isMobileSelector(store),
  shouldDisableAmazon: shouldDisableAmazonSelector(store),
  warehouses: store.search.locationsData.destination.brandLocations,
  touched: store.search?.touched[type]
});

export default connect(mapStateToProps, {
  updateField,
  getPorts,
  getLocations,
  getAddressBookAddresses
})(OriginDestination);
