import React, { useState } from 'react';
import { Dropdown, Menu, MenuItem, Drawer, Button } from '@freightos/design-system';
import { connect, push } from 'microfronts-redux';
import styled from 'styled-components/macro';

import {
  GET_RFQ_SUMMARY,
  clearServicePredictions,
  resetSearchReducerValues
} from 'slimSearch/actions';
import { getRfqSelector } from 'slimSearch/selectors';
import { mapRfqToQuote, getRfqMeasurements } from 'slimSearch/utils/mapOpenFreight.utils';

import { shipments } from './mockShipments';
import { PAGE_ROUTE } from 'slimSearch/constants';

import { cleanResults } from 'results/results.actions';

const bundleVersioin = window.allSharedComponents?.SlimSearch?.bundleVersion;
const version = bundleVersioin ? bundleVersioin?.slice(0, 7) : 'none';

const List = ({ fillQuoteForm, openDrawer, goToSlimSearch }) => (
  <Menu>
    <MenuItem>
      bundle git hash: <strong>{version}</strong>
    </MenuItem>
    <MenuItem onClick={() => goToSlimSearch()}>new slim search /</MenuItem>
    <MenuItem
      key="drawer"
      onClick={() => {
        openDrawer();
      }}
    >
      display json
    </MenuItem>
    {shipments.map((dataItem) => (
      <MenuItem
        key={dataItem.key}
        onClick={() => {
          fillQuoteForm(dataItem.data);
        }}
      >
        {dataItem.label}
      </MenuItem>
    ))}
  </Menu>
);

const mapDispatchToProps = (dispatch) => ({
  goToSlimSearch: () => {
    dispatch(resetSearchReducerValues());
    dispatch(cleanResults());
    dispatch(push(`/${PAGE_ROUTE}`));
  },
  fillQuoteForm: (data) => {
    dispatch(cleanResults());
    dispatch(clearServicePredictions());
    dispatch({
      type: GET_RFQ_SUMMARY.SUCCESS,
      payload: {
        quote: mapRfqToQuote(data),
        measurements: getRfqMeasurements(data.shipment?.load)
      }
    });
  }
});

const ListConnected = connect(null, mapDispatchToProps)(List);

const RfqJsonView = connect((state) => ({
  rfqJSON: getRfqSelector(state)
}))(({ rfqJSON = {} }) => {
  return (
    <>
      <Info>
        <h3>Open freight params:</h3>
        <h6>Copy to clipboard</h6>
        <div style={{ display: 'flex' }}>
          <Button
            type="secondary"
            size="small"
            onClick={() => navigator.clipboard.writeText(JSON.stringify(rfqJSON))}
          >
            as JSON string
          </Button>
          <Button
            style={{ marginLeft: '16px' }}
            type="secondary"
            size="small"
            onClick={() => navigator.clipboard.writeText(JSON.stringify(rfqJSON))}
          >
            as formatted JSON string
          </Button>
        </div>

        <small>
          <em>
            Both can be used as <code>?quoteParams=[copied value]</code> in URL (
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              onClick={() =>
                document.location.replace(
                  `${document.location.origin}${
                    document.location.pathname
                  }?quoteParams=${JSON.stringify(rfqJSON)}`
                )
              }
            >
              Populate URL now
            </a>
            )
          </em>
        </small>
      </Info>
      <JsonViewPre>{JSON.stringify(rfqJSON, null, 2)}</JsonViewPre>
    </>
  );
});

const AutoFillComponent = () => {
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  return (
    <>
      <Dropdown overlay={() => <ListConnected openDrawer={() => setDrawerVisibility(true)} />}>
        <Wrapper />
      </Dropdown>
      <StyledDrawer
        closable={true}
        destroyOnClose={true}
        mask={true}
        maskClosable={true}
        onClose={() => setDrawerVisibility(false)}
        visible={drawerVisibility}
        width="38vw"
      >
        <RfqJsonView />
      </StyledDrawer>
    </>
  );
};

export default AutoFillComponent;

const Wrapper = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  border: 1px solid red;
  width: 40px;
  height: 20px;
`;

const StyledDrawer = styled(Drawer)`
  padding: 0;
  .ant-drawer-header-no-title {
    display: none;
  }
  .ant-drawer-body {
    padding: 0;
  }
`;

const JsonViewPre = styled.pre`
  background: black;
  color: white;
  font-size: 15px;
  white-space: pre-wrap;
  height: 100vh;
  line-height: 2;
  padding: 16px;
  position: absolute;
  width: 100%;
`;

const Info = styled.div`
  padding: 16px;
`;
