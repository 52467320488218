/* eslint-disable default-case,no-fallthrough,no-duplicate-case */
import { store } from 'microfronts-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  omitBy,
  isUndefined,
  get,
  sortBy,
  flow,
  map,
  sum,
  groupBy,
  reduce,
  uniq,
  join,
  isFinite,
  inRange,
  getOr,
  toLower,
  omit
} from 'lodash/fp';
import moment from 'moment';
import {
  SECTIONS,
  LOAD_TYPES,
  CONTAINER_TYPES,
  ADDRESS_TYPES,
  IS_SHIPBOB,
  ORIGIN_DESTINATION_TYPES,
  MOST_COMMON_ORIGIN_COUNTRIES,
  MOST_COMMON_DESTINATION_COUNTRIES,
  GOODS_READY_RANGES,
  FULFILMENT_CENTER_COMPANIES,
  PALLET_TYPES,
  LOAD_VALIDATION,
  CONTAINER_TYPES_VALUES
} from 'slimSearch/constants';

import { getTLD, getDefaultValue } from 'utils';

import { COUNTRIES } from '@freightos/design-system';
import { t } from 'utils/translationProvider';
import { progressStepClick } from 'slimSearch/actions';

export const numberFormat = (value) => Intl.NumberFormat().format(value);

export const getLocationTypes = (type, shouldDisableAmazonAndUPS) => {
  if (shouldDisableAmazonAndUPS && type === SECTIONS.DESTINATION) {
    return omit(
      ['FULFILMENT_CENTER', 'LAST_MILE_DELIVERY'],
      ORIGIN_DESTINATION_TYPES[type.toUpperCase()]
    );
  }
  return ORIGIN_DESTINATION_TYPES[type.toUpperCase()];
};

export const getFullfilmentCompanyLabel = (key) =>
  get(
    'label',
    Object.values(FULFILMENT_CENTER_COMPANIES).find((item) => item.key === key)
  );

export const getLocationLabel = (type, locationType) =>
  get(
    'label',
    Object.values(ORIGIN_DESTINATION_TYPES[type.toUpperCase()]).find(
      (item) => item.value === locationType.locationTypeCode
    )
  );

export const getLocationIcon = (type, locationType) =>
  get(
    'icon',
    Object.values(ORIGIN_DESTINATION_TYPES[type.toUpperCase()]).find(
      (item) => item.value === locationType.locationTypeCode
    )
  );

export const getCountriesList = (
  type,
  getOriginCountries,
  getDestinationCountries,
  search = ''
) => {
  const preferred =
    type === SECTIONS.ORIGIN ? MOST_COMMON_ORIGIN_COUNTRIES : MOST_COMMON_DESTINATION_COUNTRIES;

  const preconfiguredCountries =
    type === SECTIONS.ORIGIN ? getOriginCountries : getDestinationCountries;

  const sortCountries = preferred.reduce((acc, value, index) => {
    acc[value] = index;
    return acc;
  }, {});

  let sortedPreferred;

  if (preconfiguredCountries?.length > 0) {
    sortedPreferred = COUNTRIES.filter((c) => preconfiguredCountries.includes(c.code));
  } else {
    sortedPreferred = sortBy(({ code }) => sortCountries[code], COUNTRIES);
  }

  return search?.length > 0 ? searchCountries(sortedPreferred, search) : sortedPreferred;
};

export const categoryNotValid = (category, sectionData = {}) => {
  if (!category) {
    return false;
  }

  if (category === SECTIONS.ORIGIN || category === SECTIONS.DESTINATION) {
    return sectionData[`${category}Location`]?.locationCode === undefined;
  }

  if (category === SECTIONS.GOODS) {
    return (
      !parseFloat(sectionData.goodsValue?.value) ||
      sectionData.pickupEvent?.goodsReadyRange === null ||
      sectionData.pickupEvent?.goodsReadyRange === ''
    );
  }

  if (category === SECTIONS.LOAD) {
    if (sectionData.type === LOAD_TYPES.CONTAINER.value) {
      return false; // there are default values
    }

    if (sectionData.type === LOAD_TYPES.LOOSE_CARGO.value) {
      if (sectionData.looseCargo?.calculateByTotals) {
        const minVolume = LOAD_VALIDATION.totals.volume.min[sectionData?.measurements?.volume];
        const maxVolume = LOAD_VALIDATION.totals.volume.max[sectionData?.measurements?.volume];
        const minWeight = LOAD_VALIDATION.totals.weight.min[sectionData?.measurements?.weight];
        const maxWeight = LOAD_VALIDATION.totals.weight.max[sectionData?.measurements?.weight];

        const volume =
          isFinite(sectionData.looseCargo?.totals?.volume?.value) &&
          inRange(minVolume, maxVolume, sectionData.looseCargo?.totals?.volume?.value);
        const weight =
          isFinite(sectionData.looseCargo?.totals?.weight?.value) &&
          inRange(minWeight, maxWeight, sectionData.looseCargo?.totals?.weight?.value);

        return ![volume, weight].every(Boolean);
      } else {
        const packages = sectionData.looseCargo.packages.map(
          (pkg) => !validLoadPackage(pkg, sectionData.measurements)
        );

        return !packages.every(Boolean);
      }
    }

    return false;
  }

  return false;
};

export const allSectionsValid = (search) => {
  return (
    !categoryNotValid(SECTIONS.ORIGIN, search.quote[SECTIONS.ORIGIN]) &&
    !categoryNotValid(SECTIONS.DESTINATION, search.quote[SECTIONS.DESTINATION]) &&
    !categoryNotValid(SECTIONS.LOAD, search.quote[SECTIONS.LOAD]) &&
    !categoryNotValid(SECTIONS.GOODS, search.quote[SECTIONS.GOODS])
  );
};

export const nextInvalidCategory = (search) => {
  if (categoryNotValid(SECTIONS.ORIGIN, search.quote[SECTIONS.ORIGIN])) {
    return SECTIONS.ORIGIN;
  }

  if (categoryNotValid(SECTIONS.DESTINATION, search.quote[SECTIONS.DESTINATION])) {
    return SECTIONS.DESTINATION;
  }

  if (categoryNotValid(SECTIONS.LOAD, search.quote[SECTIONS.LOAD])) {
    return SECTIONS.LOAD;
  }

  if (categoryNotValid(SECTIONS.GOODS, search.quote[SECTIONS.GOODS])) {
    return SECTIONS.GOODS;
  }

  return null;
};

export const validLoadPackage = (pkg, measurements) => {
  const minDimensions =
    LOAD_VALIDATION.dimensions[getLoadType(pkg.packagingType)].min[measurements.dimensions];
  const maxDimensions =
    LOAD_VALIDATION.dimensions[getLoadType(pkg.packagingType)].max[measurements.dimensions];
  const minWeight = LOAD_VALIDATION.weight[getLoadType(pkg.packagingType)].min[measurements.weight];
  const maxWeight = LOAD_VALIDATION.weight[getLoadType(pkg.packagingType)].max[measurements.weight];

  const height =
    isFinite(pkg.packageDimensions?.height?.value) &&
    inRange(minDimensions, maxDimensions, pkg.packageDimensions?.height?.value);
  const length =
    isFinite(pkg.packageDimensions?.length?.value) &&
    inRange(minDimensions, maxDimensions, pkg.packageDimensions?.length?.value);
  const width =
    isFinite(pkg.packageDimensions?.width?.value) &&
    inRange(minDimensions, maxDimensions, pkg.packageDimensions?.width?.value);
  const weight = isFinite(pkg?.weight?.value) && inRange(minWeight, maxWeight, pkg?.weight?.value);
  if (
    pkg.packagingType === LOAD_TYPES.PALLETS.value ||
    pkg.packagingType === LOAD_TYPES.BOX.value
  ) {
    return ![length, width, height, weight].every(Boolean);
  } else {
    return ![height, weight].every(Boolean);
  }
};

/**
 * @deprecated
 * @param {*} quote
 * @returns
 */
export const createFreightosSearchUrlParams = (quote = {}) => {
  const loadType = quote.load?.type;
  let originType = quote.origin?.originLocation?.locationTypeCode;
  let destinationType = quote.destination.destinationLocation.locationTypeCode;
  if (
    quote.destination.destinationLocation.locationTypeCode === ADDRESS_TYPES.BUSINESS_ADDRESS.value
  ) {
    if (loadType === LOAD_TYPES.CONTAINER.value) {
      destinationType = 'BusinessWithLoadingDock';
    }

    if (loadType === LOAD_TYPES.LOOSE_CARGO.value) {
      destinationType = 'BusinessWithLiftGate';
    }
  }

  if (quote.origin.originLocation.locationTypeCode === ADDRESS_TYPES.BUSINESS_ADDRESS.value) {
    if (loadType === LOAD_TYPES.CONTAINER.value) {
      originType = 'BusinessWithLoadingDock';
    }

    if (loadType === LOAD_TYPES.LOOSE_CARGO.value) {
      originType = 'BusinessWithLiftGate';
    }
  }

  if (IS_SHIPBOB) {
    destinationType = 'BusinessWithLiftGate';
  }

  if (quote.destination.destinationLocation.locationTypeCode === ADDRESS_TYPES.PORT.value) {
    destinationType = 'Port';
  }

  if (quote.origin.originLocation.locationTypeCode === ADDRESS_TYPES.PORT.value) {
    originType = 'Port';
  }

  if (destinationType === 'residential') {
    destinationType = 'Residential';
  }

  if (originType === 'residential') {
    originType = 'Residential';
  }

  if (destinationType === ADDRESS_TYPES.FACTORY_WAREHOUSE.value) {
    destinationType = 'Warehouse';
  }

  if (originType === ADDRESS_TYPES.FACTORY_WAREHOUSE.value) {
    originType = 'Warehouse';
  }

  let rest = {};
  let analytics = {};
  if (loadType === 'looseCargo') {
    const totals = quote.load?.looseCargo?.totals;
    rest = {
      quantity: totals.quantity,
      totalVolume: totals.volume.value,
      totalVolumeUnit: totals.volume.unitCode.toUpperCase(),
      totalWeight: totals.weight.value,
      totalWeightUnit: totals.weight.unitCode.toUpperCase(),
      calculateBy: 'totals',
      loadType
    };
  } else {
    const containerLoad = quote.load?.container?.packages[0];
    rest = {
      quantity: containerLoad.quantity,
      overweight: containerLoad.overWeightIndicator ? true : undefined,
      loadType: containerLoad.packagingType?.toLowerCase().replace(/hc/, 'HC')
    };
  }
  const parentUrl =
    window.location !== window.parent.location ? document.referrer : document.location.href;

  analytics = {
    utm_medium: 'poc_widget',
    utm_referrer: getTLD(parentUrl),
    widget_search_key: uuidv4(),
    host_page_url: getDefaultValue('host_page_url')
  };

  return omitBy(isUndefined, {
    ...rest,
    origin: quote.origin.originLocation.locationCode,
    originType,
    originCountry: quote.origin.originLocation.countryID.value,
    isOriginPort: originType === 'Port',
    destination: quote.destination.destinationLocation.locationCode,
    destinationType,
    destinationCountry: quote.destination.destinationLocation.countryID.value,
    pickupDate: moment(quote.goods.pickupEvent.eventDate.scheduledDateTime).valueOf(),
    insurance: quote.goods.goodsValue.value ? true : undefined,
    shipmentValue: quote.goods.goodsValue.value,
    hazardous: quote.load.hazardous ? quote.load.hazardous : undefined,
    widget: true,
    ...analytics
  });
};

export const getContainersCalculated = (container) =>
  flow(
    groupBy('packagingType'),
    reduce((result, value) => {
      if (value.length) {
        const qty = sum(map('quantity', value)) || 1;
        const type = CONTAINER_TYPES.find(
          (container) => container.value === value[0].packagingType
        ).label;

        result.push(`${qty} × ${type} ${qty > 1 ? 'containers' : 'container'}`);
      }

      return result;
    }, []),
    join(', ')
  )(container?.packages);

export const getTotalsCalculated = (totals, measurements) => {
  let value = `${totals.quantity || 1} ${totals.quantity === 1 ? 'Unit' : 'Units'} | `;

  if (totals.volume.value) {
    value += `Total: ${isFinite(totals.volume.value) ? totals.volume.value : 0} ${
      measurements.volume
    }`;
  }

  if (totals.weight.value) {
    value += `, ${isFinite(totals.weight.value) ? totals.weight.value : 0} ${measurements.weight}`;
  }

  return value;
};

export const filteredLocationsList = (listOfLocations, country, isUps = false, search = '') => {
  let list = [];
  if (IS_SHIPBOB && !isUps) {
    list = listOfLocations.filter((location) => location.brand === 'SHIPBOB');
  } else if (isUps) {
    list = listOfLocations.filter((location) => location.brand === 'UPS');
  } else {
    list = listOfLocations.filter((location) => location.brand !== 'UPS');
  }

  let sortedList;

  if (search.length > 0) {
    sortedList = list.filter((l) => toLower(l.label).includes(toLower(search)));
  } else {
    sortedList = list;
  }

  if (country) {
    return sortedList.filter((location) => location.country === country);
  }

  return sortedList;
};

export const filteredCountriesList = (type, listOfLocations) => {
  const preferred = flow([map('country'), uniq])(filteredLocationsList(listOfLocations, null));

  return COUNTRIES.filter((country) => preferred.includes(country.code));
};

export const searchCountries = (list, search) =>
  list.filter(
    (country) =>
      toLower(country.name).includes(toLower(search)) ||
      toLower(country.code).includes(toLower(search))
  );

export const getEventDates = (goodsReadyRange) => {
  if (goodsReadyRange === GOODS_READY_RANGES.READY_NOW) {
    return {
      scheduledDateTime: moment.utc().add(1, 'days').format('YYYY-MM-DDT00:00:00Z'),
      endDateTime: ''
    };
  }
  if (goodsReadyRange === GOODS_READY_RANGES.READY_IN_TWO_WEEKS) {
    return {
      scheduledDateTime: moment.utc().add(1, 'days').format('YYYY-MM-DDT00:00:00Z'),
      endDateTime: moment.utc().add(1, 'days').add(2, 'weeks').format('YYYY-MM-DDT00:00:00Z')
    };
  }
  if (goodsReadyRange === GOODS_READY_RANGES.READY_IN_MORE_THAN_TWO_WEEKS) {
    return {
      scheduledDateTime: moment.utc().add(1, 'days').add(2, 'weeks').format('YYYY-MM-DDT00:00:00Z'),
      endDateTime: moment.utc().add(1, 'days').add(4, 'weeks').format('YYYY-MM-DDT00:00:00Z')
    };
  }

  return {
    scheduledDateTime: null,
    endDateTime: null
  };
};

export const getEventDatesTexts = (goodsReadyRange) => {
  if (goodsReadyRange === GOODS_READY_RANGES.READY_NOW) {
    return 'Goods are ready';
  }
  if (goodsReadyRange === GOODS_READY_RANGES.READY_IN_TWO_WEEKS) {
    return 'Within 2 weeks';
  }
  if (goodsReadyRange === GOODS_READY_RANGES.READY_IN_MORE_THAN_TWO_WEEKS) {
    return 'More than 2 weeks';
  }

  return null;
};

export const getLoadTypeLabel = (type) => {
  if (type.startsWith(LOAD_TYPES.CONTAINER.value)) {
    return LOAD_TYPES.CONTAINER.label;
  } else {
    if (type.startsWith('pallet')) {
      if (type === LOAD_TYPES.PALLETS.value) {
        return LOAD_TYPES.PALLETS.label;
      }

      return PALLET_TYPES.find((pallet) => pallet.value === type).label;
    }

    if (type === LOAD_TYPES.BOX.value) {
      return LOAD_TYPES.BOX.label;
    }
  }
};

export const getLoadType = (type) => {
  if (type.startsWith(LOAD_TYPES.CONTAINER.value)) {
    return LOAD_TYPES.CONTAINER.value;
  } else {
    if (type.startsWith('pallet')) {
      return LOAD_TYPES.PALLETS.value;
    }

    if (type === LOAD_TYPES.BOX.value) {
      return LOAD_TYPES.BOX.value;
    }
  }
};

export const getPackageSizes = (pkg, measurements) => {
  if (pkg.packagingType.startsWith(LOAD_TYPES.CONTAINER.value)) {
    return CONTAINER_TYPES.find((container) => container.value === pkg.packagingType).label;
  } else {
    return `${
      pkg.packageDimensions?.length?.value ? pkg.packageDimensions?.length?.value + '×' : ''
    }${pkg.packageDimensions?.width?.value ? pkg.packageDimensions.width.value + '×' : ''}${
      pkg.packageDimensions?.height?.value
        ? pkg.packageDimensions?.height?.value + ' ' + measurements.dimensions.toUpperCase()
        : ''
    } ${pkg.weight?.value ? pkg.weight.value + ' ' + measurements.weight.toUpperCase() : ''}`;
  }
};

export const countPackagesQuantity = (packages) =>
  (packages || []).reduce((acc, { quantity }) => {
    return acc + quantity;
  }, 0);

export const sectionSuccessForAnalytics = (section, sectionData) => {
  if (section === SECTIONS.ORIGIN) {
    return {
      origin_type: sectionData?.originLocation?.locationTypeCode,
      origin_country: sectionData?.originLocation?.countryID?.value,
      origin_address: sectionData?.originLocation?.locationCode,
      origin_port:
        sectionData?.originLocation?.locationTypeCode === ADDRESS_TYPES.PORT.value
          ? sectionData?.originLocation?.locationCode
          : null,
      used_saved_address: !!sectionData?.originLocation?.locationCompany
    };
  }

  if (section === SECTIONS.DESTINATION) {
    return {
      destination_type: sectionData?.destinationLocation?.locationTypeCode,
      destination_country: sectionData?.destinationLocation?.countryID?.value,
      destination_address: sectionData?.destinationLocation?.locationCode,
      destination_port:
        sectionData?.destinationLocation?.locationTypeCode === ADDRESS_TYPES.PORT.value
          ? sectionData?.destinationLocation?.locationCode
          : null,
      used_saved_address: !!sectionData?.destinationLocation?.locationCompany
    };
  }

  if (section === SECTIONS.LOAD) {
    let load;
    if (sectionData.looseCargo?.calculateByTotals) {
      load = {
        load_type: 'total_weight_volume',
        num_of_units: sectionData.looseCargo.totals.quantity,
        total_volume: sectionData.looseCargo.totals?.volume?.value,
        total_weight: sectionData.looseCargo.totals?.weight?.value
      };
    }

    if (sectionData.type === 'container') {
      load = {
        load_type: 'fcl',
        num_of_units: countPackagesQuantity(sectionData.container?.packages),
        total_volume: null,
        total_weight: null
      };
    }

    if (!sectionData.looseCargo?.calculateByTotals && sectionData.type === 'looseCargo') {
      load = {
        load_type: 'loose_cargo',
        num_of_units: countPackagesQuantity(sectionData.looseCargo?.packages),
        total_volume: null,
        total_weight: null
      };
    }

    return load;
  }

  if (section === SECTIONS.GOODS) {
    return {
      goods_currency: sectionData?.goodsValue?.currency,
      goods_value: sectionData?.goodsValue?.value,
      goods_ready_start_date: getEventDates(sectionData?.pickupEvent?.goodsReadyRange)
        .scheduledDateTime,
      goods_ready_end_date: getEventDates(sectionData?.pickupEvent?.goodsReadyRange).endDateTime
    };
  }

  return {};
};

export const countryCodeToCountryName = (code) => {
  if (code.length === 2) {
    const country = COUNTRIES.find((countryItem) => countryItem.code === code);

    return getOr(code, 'name', country);
  }

  return code;
};

export const countryNameToCountryCode = (name) => {
  if (name.length > 3) {
    const country = COUNTRIES.find((countryItem) => countryItem.name === name);

    return getOr(name, 'code', country);
  }

  return name;
};

export const loadHas45Container = (load) =>
  load.container.packages.some(
    (pkg) => pkg.packagingType === CONTAINER_TYPES_VALUES['container45HC'].value
  );

const preBookingSteps = (isMobile) => {
  return [
    {
      label: t('search/step-progress/search', 'Search'),
      value: 'search',
      onClick: () => store.dispatch(progressStepClick({ step: 'search' }))
    },
    {
      label: isMobile
        ? t('search/step-progress/recommended-services-mobile', 'Services')
        : t('search/step-progress/recommended-services', 'Recommended Services'),
      value: 'recommended-services',
      onClick: () => store.dispatch(progressStepClick({ step: 'recommended-services' }))
    },
    {
      label: t('search/step-progress/results', 'Results'),
      value: 'results',
      onClick: () => store.dispatch(progressStepClick({ step: 'results' }))
    }
  ];
};

export const progressSteps = (isMobile = false, isOrdersEnabled = true) =>
  isOrdersEnabled
    ? [
        ...preBookingSteps(isMobile),
        {
          label: t('search/step-progress/booking', 'Booking'),
          value: 'booking',
          onClick: () => store.dispatch(progressStepClick({ step: 'booking' }))
        },
        {
          label: t('search/step-progress/verification', 'Verification'),
          value: 'verification',
          onClick: () => store.dispatch(progressStepClick({ step: 'verification' }))
        }
      ]
    : [...preBookingSteps(isMobile)];

export const getCountryFlag = (code = 'US') => COUNTRIES.find((c) => c.code === code).flag;

export const addressBookAddresses = (locationsData, search = '') =>
  locationsData['addressBook']
    .map((address) => ({
      ...address,
      type: 'address-book',
      addressId: address.id,
      value: `${address.city}`,
      label: `${address.company} ${address.address} ${address.city} ${address.zipCode}`
    }))
    .filter((l) => toLower(l.label).includes(toLower(search)));
