import React, { useEffect, useRef, useState } from 'react';
import { Button, Icon } from '@freightos/design-system';
import styled, { createGlobalStyle, css, keyframes } from 'styled-components/macro';
import {
  fdsBoxShadowSm,
  fdsComponentSecondaryColor,
  fdsComponentSpacingLg,
  fdsComponentSpacingSm,
  fdsFontSizeSm,
  fdsFontWeightRegular
} from '@freightos/design-system/dist/tokens';
import { connect } from 'microfronts-redux';
import {
  editSearch,
  search,
  setDropDownHeight,
  setSearchMode,
  setSearchSessionIdOn,
  updateField
} from 'slimSearch/actions';
import HolidaysAlert from 'slimSearch/components/HolidaysAlert';
import Categories from 'slimSearch/components/Categories';
import { PAGE_WIDTH, ResultsScreenValues, WIDGET_SEARCH_WITH_URL } from 'slimSearch/constants';
import AutoFillForm from 'slimSearch/dev/AutoFillForm';
import { ReactComponent as PlaceHolder } from 'slimSearch/img/search-widget-illustration-01.svg';
import { isMobileSelector } from 'slimSearch/selectors';
import { StyledCategoryCard } from 'slimSearch/sharedStyles';
import { allSectionsValid } from 'slimSearch/utils';
import { t } from 'utils/translationProvider';
import { v4 as uuidv4 } from 'uuid';
import userModel from 'propera/user';
import {
  LogInForm,
  SignUpForm,
  AuthUiSelectors,
  AuthUiActions,
  AuthUiConstants
} from 'propera/authUI';
import { AuthTemplate } from 'propera/authUI/components';

const { isLoggedIn } = userModel;
const { setView } = AuthUiActions;
const { VIEWS } = AuthUiConstants;
const tada = keyframes`
  0% {
    opacity: 0.25;
  }

  100% {
    opacity: 1;
  }
`;

const GlobalStyle = createGlobalStyle`
  html body {
    background: #fff;
      .inner-content {
        background: white;
        transition: background 1s linear;
      }

    .ant-form-item-label {
      padding: 0;
    }
  }
`;

const GlobalStyleGreyBg = createGlobalStyle`
  html body {
      .inner-content {
        background: #f6f6f6;
        transition: background 1s linear;

        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type="number"] {
          -moz-appearance: textfield;
        }
      }
  }

  ${StyledCategoryCard} {
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
`;

export const SearchCategories = ({
  className = '',
  searchAction,
  isMobile,
  search,
  updateField,
  setSearchSessionIdOnAction,
  setDropDownHeightAction,
  editSearch,
  mode = 'search',
  isLoggedIn,
  loginView,
  isLoginModalOpen,
  isFirstTime,
  setView,
  setSearchModeAction,
  reviewsPanelVisible
}) => {
  const categoryRef = useRef(null);
  const isAllSectionsValid = allSectionsValid(search);

  let button = undefined;
  if (mode === 'results' || mode === 'custom-quote') {
    button = 'edit';
  } else if (mode === 'search') {
    button = 'arrow-forward';
  }

  useEffect(() => {
    if (!isLoggedIn) {
      setView({ view: isFirstTime ? VIEWS.SIGN_UP : VIEWS.LOG_IN });
    }
  }, [isFirstTime, isLoggedIn, setView]);

  useEffect(() => {
    if (document.location.search.includes('widget=true') && !isLoggedIn) {
      searchAction();
    }
  }, [isLoggedIn, searchAction]);

  const [scrollTop, setScrollTop] = useState(0);

  const onScroll = () => setScrollTop(document.getElementById('results-view').scrollTop);

  useEffect(() => {
    if (isMobile && mode === 'results' && document.getElementById('results-view')) {
      document.getElementById('results-view').addEventListener('scroll', onScroll);

      return () => document.getElementById('results-view').removeEventListener('scroll', onScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTop]);

  useEffect(() => {
    setSearchSessionIdOnAction(uuidv4());
  }, [setSearchSessionIdOnAction]);

  useEffect(() => {
    if (search.activeSection === 'load') {
      handleResize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.activeSection]);

  useEffect(() => {
    setSearchModeAction(mode);
    if (isMobile) {
      // this is to trick browser search bar on mobile (it is not always visible on scroll, thus making 100vh incorrect)
      // then in CSS: use `height: calc(var(--search-vh, 1vh) * 100);`
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--search-vh', `${vh}px`);
    }

    /* data asked to remove for now */ // searchAnalytics({ name: ANALYTICS_EVENT_NAME.SS_COMPONENT_VIEWED });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSearchModeAction(mode);
    // eslint-disable-next-line
  }, [mode]);

  const handleResize = () => {
    setDropDownHeightAction(
      window.innerHeight -
        50 - // footer space
        (categoryRef.current.getBoundingClientRect().top +
          categoryRef.current.getBoundingClientRect().height)
    );
  };

  useEffect(() => {
    if (!isMobile) {
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    // eslint-disable-next-line
  }, [categoryRef, isMobile]);

  const getButtonType = () => {
    if (mode === 'results' && isMobile) {
      return 'text';
    }

    if (!isAllSectionsValid && mode !== 'results') {
      return 'tertiary';
    }

    return 'primary';
  };
  const isMobileServicesInResults = isMobile && mode !== 'services-results';
  const smallHeader = scrollTop > 30 && isMobileServicesInResults;

  return (
    <>
      {isLoginModalOpen && !isLoggedIn ? (
        <AuthTemplate
          headings={
            loginView === VIEWS.LOG_IN // if log-in else sign-up
              ? {
                  header: t('signUp/slim_search_login_header_1_text', 'Welcome back!'),
                  subHeader: t(
                    'signUp/slim_search_login_header_2_text',
                    'Log in to complete your search'
                  )
                }
              : {
                  header: t('signUp/slim_search_sign_up_header_1_text', "You're almost there!"),
                  subHeader: t(
                    'signUp/slim_search_sign_up_header_2_text',
                    'Sign up for free to complete your search.'
                  )
                }
          }
          inModal
          viewConfig={{ view: loginView }}
        >
          {loginView === VIEWS.LOG_IN ? <LogInForm /> : <SignUpForm />}
        </AuthTemplate>
      ) : null}

      {localStorage && localStorage.getItem('debug') === 'true' && <AutoFillForm />}
      <SearchCategoriesWrapper
        isMobile={isMobile}
        isWidget={WIDGET_SEARCH_WITH_URL}
        className={className}
      >
        {mode !== 'results' && <HolidaysAlert />}
        {/* without props affix acts as div */}
        <HeaderHolder>
          <CategoriesWrapper
            inResults={document.getElementById('results-view')}
            smallHeader={smallHeader}
            isMobile={isMobile}
            data-test-id="search-categories"
            ref={categoryRef}
            mode={mode}
            reviewsPanelVisible={reviewsPanelVisible}
          >
            {search.activeSection !== null && !isMobile ? <GlobalStyleGreyBg /> : <GlobalStyle />}
            <Categories smallHeader={smallHeader} mode={mode} isMobile={isMobile} />
            {!button ? null : (
              <SearchButton
                tabIndex={0}
                isMobile={isMobile}
                mode={mode}
                block={isMobile && !(mode === 'results' && isMobile)}
                data-test-id="search-button"
                size="large"
                disabled={(!isAllSectionsValid && mode !== 'results') || search.searchInProgress}
                pulse={isAllSectionsValid || mode === 'results'}
                type={getButtonType()}
                onClick={() => {
                  updateField('activeSection', null);
                  if (button === 'edit') {
                    editSearch();
                  } else {
                    searchAction();
                  }
                }}
              >
                {isMobile ? (
                  <span>
                    {mode !== 'results' && t('Continue', 'Continue')} <Icon type={button} />{' '}
                  </span>
                ) : (
                  <Icon type={search.searchInProgress ? 'loading' : button} size="medium" />
                )}
              </SearchButton>
            )}
          </CategoriesWrapper>
        </HeaderHolder>
        {WIDGET_SEARCH_WITH_URL && (
          <PoweredBy data-test-id={`search-categories-powered-by`}>
            <div>
              Powered by:{' '}
              <FreightosLogo
                data-test-id={`search-categories-fo-logo`}
                onClick={() => window.open('https://freightos.com', '_blank')}
                alt="Freightos"
                src="https://festatic.freightos.com/microfrontsc/propera-fw/e44fab9f5f852c0ebe352d2a966d000ea1df8d47/assets/img/freightos.svg"
              />
            </div>
          </PoweredBy>
        )}
        {WIDGET_SEARCH_WITH_URL && <PlaceHolderImage data-test-id={`search-categories-image`} />}
      </SearchCategoriesWrapper>
    </>
  );
};

const SearchCategoriesWrapper = styled.div`
  ${({ isWidget }) =>
    isWidget
      ? css`
          max-width: 1168px;
        `
      : css`
          max-width: ${PAGE_WIDTH + 'px'};
        `}
  margin: 0 auto;
  ${({ isMobile }) =>
    isMobile
      ? css`
          width: 100%;
        `
      : css`
          @media (min-width: ${ResultsScreenValues.desktop}px) {
            width: 100%;
          }
          @media (max-width: ${ResultsScreenValues.desktop - 1}px) {
            width: 80vw;
          }
        `}
  position: relative;
`;

const bigToSmall = keyframes`
  from {
    height: 100px;
  }
  to {
    height: 37px;
  }
`;

const smallToBig = keyframes`
  from {
    height: 37px;
  }
  to {
    height: 100px;
  }
`;

const CategoriesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 18px;
  position: relative;
  background: white;
  ${({ isMobile }) =>
    isMobile
      ? css`
          flex-direction: column;
          font-size: 10px;
        `
      : css`
          box-shadow: ${fdsBoxShadowSm};
          border: 1px solid ${fdsComponentSecondaryColor};
          border-radius: 6px;
        `}

  h5 {
    margin: 0 0 ${fdsComponentSpacingSm} 0;
    font-size: 10px;
  }
  p {
    margin: 0;
    font-size: ${fdsFontSizeSm};
  }

  ${({ isMobile, mode }) =>
    isMobile && mode !== 'search' && mode !== 'services-results'
      ? css`
          background: white;
          padding: 8px 16px;
          line-height: 21px;
          box-shadow: ${fdsBoxShadowSm};
        `
      : ''}

  ${({ isMobile, mode }) =>
    isMobile && mode === 'results'
      ? css`
          ${({ reviewsPanelVisible, inResults }) =>
            reviewsPanelVisible || inResults === null
              ? ''
              : css`
                  position: fixed;
                  width: 100vw;
                  z-index: 2;
                  top: 60px;
                `}

          ${({ smallHeader }) =>
            smallHeader
              ? css`
                  animation: ${bigToSmall} 0.3s ease-in-out both;
                  height: 37px;
                `
              : css`
                  animation: ${smallToBig} 0.3s ease-in-out both;
                  height: 100px;
                `}
        `
      : ''}
`;

const PoweredBy = styled.div`
  padding: ${fdsComponentSpacingLg} 0;
  font-size: ${fdsFontSizeSm};
  font-weight: ${fdsFontWeightRegular};
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  position: relative;

  img {
    width: 90px;
  }
`;

const FreightosLogo = styled.img`
  cursor: pointer;
`;

const PlaceHolderImage = styled(PlaceHolder)`
  // widget must be max-height 400px
  height: 270px;
  margin: -50px auto 0;
  display: block;
`;

const SearchButton = styled(Button)`
  ${({ isMobile }) =>
    isMobile
      ? ''
      : css`
          width: 48px;
        `}
  align-self: center;
  margin: 0 ${fdsComponentSpacingSm};

  ${({ isMobile, mode }) =>
    isMobile && mode === 'results'
      ? css`
          position: absolute;
          right: 0;
          height: 19px;
        `
      : ''}

  ${({ pulse }) =>
    pulse
      ? css`
          transform: scale(1);
          animation: ${tada} 0.8s 0.1s ease-in-out;
        `
      : ''}
`;

const HeaderHolder = styled.div``;

const mapStateToProps = (store) => ({
  search: store.search,
  isMobile: isMobileSelector(store),
  isLoggedIn: isLoggedIn(store),
  isFirstTime: store.propera?.user?.isFirstTime,
  loginView: AuthUiSelectors.getView(store),
  isLoginModalOpen: AuthUiSelectors.getLoginModalState(store),
  reviewsPanelVisible: store?.reviews?.panel?.isVisible ?? false
});

export default connect(mapStateToProps, {
  searchAction: search,
  editSearch,
  updateField,
  setView,
  setSearchSessionIdOnAction: setSearchSessionIdOn,
  setDropDownHeightAction: setDropDownHeight,
  setSearchModeAction: setSearchMode
})(SearchCategories);
