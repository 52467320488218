import React from 'react';
import { connect } from 'microfronts-redux';
import { map, upperFirst, get, flow, values, flatten, toLower, concat, sortBy } from 'lodash/fp';
import styled, { css } from 'styled-components/macro';
import { FixedSizeList as List } from 'react-window';
import { Avatar, Icon, Input, RadioGroup } from '@freightos/design-system';
import {
  fdsComponentSecondaryColorDisabled,
  fdsComponentSpacingLg,
  fdsSizeMd,
  fdsSizeSm,
  fdsComponentSpacingSm,
  fdsH6FontSize,
  fdsComponentTertiaryColorClick,
  primaryColorToken
} from '@freightos/design-system/dist/tokens';
import { t } from 'utils/translationProvider';
import model from 'propera/siteConfig';
import {
  MobileClose,
  CategoryOverlayWrapper,
  MobileSectionTitle,
  MobileSectionSubTitle,
  StyledFormItem
} from 'slimSearch/sharedStyles';
import {
  getAddressBookAddresses,
  getLocations,
  getPorts,
  updateField,
  setUserAddressFromAddressBook
} from 'slimSearch/actions';
import { isMobileSelector, shouldDisableAmazonSelector } from 'slimSearch/selectors';
import {
  getLocationLabel,
  getLocationTypes,
  getCountriesList,
  filteredLocationsList,
  filteredCountriesList,
  getLocationIcon,
  getCountryFlag,
  searchCountries,
  addressBookAddresses
} from 'slimSearch/utils';
import { ADDRESS_TYPES, SECTIONS, MIN_CHARS_TO_START_SEARCH } from 'slimSearch/constants';

import { Brand } from 'slimSearch/components/sections/locations/Brand';
import { AddressBookItem } from 'slimSearch/sharedStyles';

const { getOriginCountries, getDestinationCountries } = model;

const BreadCrumbSeparator = () => <Pipe>&rsaquo;</Pipe>;

export const OriginDestinationMobile = ({
  title,
  type,
  shouldDisableAmazon,
  updateField,
  locationType,
  getOriginCountries,
  getDestinationCountries,
  locations,
  warehouses,
  getPorts,
  getLocations,
  isUps,
  brandCountries,
  pristine,
  getAddressBookAddresses,
  setUserAddressFromAddressBook
}) => {
  // on mobile we dont use predefined, product/UX request, so need to reset
  React.useEffect(() => {
    if (pristine || !locationType?.locationCode) {
      updateField(`quote.${type}.${type}Location.locationTypeCode`, null);
      updateField(`quote.${type}.${type}Location.countryID.value`, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listOfWarehouses = flow([values, flatten])(warehouses);
  const [activeBreadCrumb, setActiveBreadCrumb] = React.useState('type');
  const [countryFilter, setCountryFilter] = React.useState('');
  const [addressSearch, setAddressSearch] = React.useState('');

  const isPort = locationType.locationTypeCode === ADDRESS_TYPES.PORT.value;
  const isFulfilmentCenter =
    locationType.locationTypeCode === ADDRESS_TYPES.FULFILMENT_CENTER.value;
  const isLastMile = locationType.locationTypeCode === ADDRESS_TYPES.LAST_MILE_DELIVERY.value;
  const country = locationType?.countryID?.value;
  const locationCode = () => {
    if (isFulfilmentCenter) {
      return get(
        'ecommerceKey',
        listOfWarehouses.find((address) => address.value === locationType?.locationCode)
      );
    }

    return locationType?.locationCode;
  };

  React.useEffect(() => {
    if (type === SECTIONS.ORIGIN && !isFulfilmentCenter && !isLastMile && !isPort && country) {
      getAddressBookAddresses({ type: 'pickup', country });
    }
    if (type === SECTIONS.DESTINATION && !isFulfilmentCenter && !isLastMile && !isPort && country) {
      getAddressBookAddresses({ type: 'delivery', country });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const locationTypeMap = () => {
    if (addressSearch.length < MIN_CHARS_TO_START_SEARCH && !isLastMile && !isFulfilmentCenter) {
      return [];
    }

    if (isPort) {
      return locations.ports || [];
    }

    if (isLastMile && type === SECTIONS.DESTINATION) {
      return (
        filteredLocationsList(listOfWarehouses, locationType.countryID.value, isUps).filter(
          (address) => address.value && toLower(address.value).includes(toLower(addressSearch))
        ) || []
      );
    }

    if (isFulfilmentCenter && type === SECTIONS.DESTINATION) {
      return sortBy(
        ['value'],
        filteredLocationsList(listOfWarehouses, locationType.countryID.value).filter(
          (address) => address.value && toLower(address.value).includes(toLower(addressSearch))
        ) || []
      );
    }

    return (
      concat(locations.addresses, isPort ? [] : addressBookAddresses(locations, addressSearch)) ||
      []
    );
  };

  const getCountries = () => {
    if (isFulfilmentCenter) {
      return searchCountries(filteredCountriesList(type, listOfWarehouses), countryFilter);
    }
    if (isLastMile) {
      return brandCountries;
    }

    return searchCountries(
      getCountriesList(type, getOriginCountries, getDestinationCountries),
      countryFilter
    );
  };

  return (
    <OriginDestinationMobileWrapper>
      <CategoryOverlayWrapper>
        <MobileClose>
          <Icon
            data-test-id={`search-mobile-close`}
            type="close"
            onClick={() => {
              updateField('activeSection', null, { section: type });
              setActiveBreadCrumb('type');
            }}
          />
        </MobileClose>

        <>
          <MobileSectionTitle data-test-id={`search-mobile-title`}>
            {upperFirst(type)}
          </MobileSectionTitle>
          <MobileSectionSubTitle data-test-id={`search-mobile-subtitle`}>
            {title}
          </MobileSectionSubTitle>
          <BreadCrumbs>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <BreadCrumb
              data-test-id={`search-mobile-breadcrumb-type`}
              active={activeBreadCrumb === 'type'}
              onClick={() => setActiveBreadCrumb('type')}
            >
              <>
                {getLocationLabel(type, locationType) && (
                  <Icon type={getLocationIcon(type, locationType)} />
                )}{' '}
                {getLocationLabel(type, locationType) || `${upperFirst(type)} type`}
              </>
            </BreadCrumb>
            <BreadCrumbSeparator />
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span onClick={() => setActiveBreadCrumb('country')}>
              {locationType.countryID.value ? (
                <StyledAvatar
                  data-test-id={`search-mobile-breadcrumb-country-${locationType.countryID.value}`}
                  bordered
                  active={activeBreadCrumb === 'country'}
                  country={getCountryFlag(locationType.countryID.value)}
                  type="flag"
                  size="small"
                />
              ) : (
                <StyledAvatar
                  data-test-id={`search-mobile-breadcrumb-country-empty`}
                  type="neutral"
                  size="small"
                  active={activeBreadCrumb === 'country'}
                >
                  <Icon type="sphere" />
                </StyledAvatar>
              )}
            </span>
            <BreadCrumbSeparator />
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <BreadCrumb
              data-test-id={`search-mobile-breadcrumb-address`}
              active={activeBreadCrumb === 'address'}
              onClick={() => setActiveBreadCrumb('address')}
            >
              {locationCode() ? locationCode() : 'Address'}
            </BreadCrumb>
          </BreadCrumbs>
        </>

        {activeBreadCrumb === 'type' && (
          <StyledFormItem label="Select type">
            <RadioGroup
              data-test-id={`search-mobile-location-type`}
              card
              value={locationType.locationTypeCode}
              onChange={(event) => {
                updateField(`quote.${type}.${type}Location.locationTypeCode`, event.target.value);
                setActiveBreadCrumb('country');
              }}
              options={map(
                (item) => ({
                  label: (
                    <StyledTypeItem
                      data-test-id={`search-mobile-location-type-item-${item.label}`}
                      onMouseDown={() => {
                        updateField(`quote.${type}.${type}Location.locationTypeCode`, item.value);
                        updateField(`quote.${type}.${type}Location.locationType`, item.type);
                        setActiveBreadCrumb('country');
                      }}
                    >
                      <span>
                        <Icon type={item.icon} /> {item.label}
                      </span>
                    </StyledTypeItem>
                  ),
                  value: item.value
                }),
                getLocationTypes(type, shouldDisableAmazon)
              )}
              vertical
            />
          </StyledFormItem>
        )}

        {activeBreadCrumb === 'country' && (
          <div>
            <StyledFormItem label="Country">
              <Input
                data-test-id={`search-mobile-country-input`}
                size="large"
                style={{ fontSize: fdsH6FontSize }}
                onChange={(event) => setCountryFilter(event.target.value)}
                placeholder="Enter country name"
              />
            </StyledFormItem>

            <List
              overscanCount={8}
              height={document.documentElement.clientHeight * 0.63}
              itemCount={getCountries().length}
              itemSize={52}
            >
              {({ index, style }) => {
                const country = getCountries()[index];

                return (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                  <Item
                    data-test-id={`${type}-country-select-value-${country.code}`}
                    title={country.name}
                    style={{ ...style }}
                    active={country.code === locationType.countryID.value}
                    key={country.code}
                    onMouseDown={() => {
                      updateField(`quote.${type}.${type}Location.countryID.value`, country.code);
                      setCountryFilter('');
                      setActiveBreadCrumb('address');
                    }}
                  >
                    <Avatar country={country.flag || country.code} type="flag" size="small" />{' '}
                    {country.name}
                  </Item>
                );
              }}
            </List>
          </div>
        )}

        {activeBreadCrumb === 'address' && (
          <div>
            <StyledFormItem label="Address">
              <Input
                allowClear
                data-test-id={`search-mobile-address-input`}
                size="large"
                disabled={!locationType?.countryID?.value}
                style={{ fontSize: fdsH6FontSize }}
                onChange={(event) => {
                  if (event.type === 'click') {
                    setAddressSearch('');
                    updateField(`quote.${type}.${type}Location.locationCode`, undefined);
                    return;
                  }

                  setAddressSearch(event.target.value);
                  if (event.target.value.length >= MIN_CHARS_TO_START_SEARCH) {
                    isPort
                      ? getPorts({
                          country: locationType.countryID.value,
                          searchTerm: event.target.value,
                          type
                        })
                      : getLocations({
                          country: locationType.countryID.value,
                          searchTerm: event.target.value,
                          type
                        });
                  }
                }}
                placeholder={
                  isPort
                    ? t('search/ss_destination_port_prompt_text', 'Enter port/airport code')
                    : t(
                        'search/ss_destination_addresearch/ss_prompt_text',
                        'Enter city or zip code'
                      )
                }
                value={addressSearch.length > 0 ? addressSearch : locationCode()}
              />
            </StyledFormItem>
            <ItemsList>
              {locationTypeMap().map((location) => (
                <Item
                  key={location.value}
                  value={location.value}
                  active={location.label === locationType.locationCode}
                  data-test-id={`search-mobile-address-item`}
                  onMouseDown={() => {
                    updateField(`quote.${type}.${type}Location.locationCode`, location.value);
                    updateField(`quote.${type}.${type}Location.locationType`, location.type);

                    if (location.type === 'address-book') {
                      setUserAddressFromAddressBook({ type, address: location });
                      updateField(
                        `quote.${type}.${type}Location.locationAddressBookId`,
                        location.id
                      );
                      updateField(
                        `quote.${type}.${type}Location.locationZipCode`,
                        location.zipCode
                      );
                      updateField(
                        `quote.${type}.${type}Location.locationCompany`,
                        location?.company
                      );
                      updateField(
                        `quote.${type}.${type}Location.stateOrProvince`,
                        location?.stateOrProvince
                      );
                    } else {
                      setUserAddressFromAddressBook({ type, address: null });
                      updateField(`quote.${type}.${type}Location.locationAddressBookId`, null);
                      updateField(`quote.${type}.${type}Location.locationZipCode`, null);
                      updateField(`quote.${type}.${type}Location.locationCompany`, null);
                      updateField(`quote.${type}.${type}Location.stateOrProvince`, null);
                    }

                    if (isFulfilmentCenter) {
                      updateField(`quote.${type}.${type}Location.brand`, location.brand);
                      updateField(`quote.${type}.${type}Location.label`, location.value);
                      updateField(
                        `quote.${type}.${type}Location.ecommerceKey`,
                        location.ecommerceKey
                      );
                    }
                    setTimeout(() => {
                      updateField('activeSection', null, { section: type });
                      setActiveBreadCrumb('type');
                    }, 500);
                  }}
                >
                  {location.type === 'address-book' ? (
                    <AddressBookItem>
                      <div>
                        <Icon type={location.type} size="small" />
                      </div>
                      <div>
                        <strong>{upperFirst(location.company)}</strong>
                        <br />
                        {location.address}, {location.city} {location.zipCode}
                      </div>
                    </AddressBookItem>
                  ) : (
                    <BrandWrapper>
                      <div>
                        {location.type && <Icon type={location.type} size="small" />}
                        {location.brand && <Brand name={location.brand} />}
                      </div>
                      <div>
                        {isFulfilmentCenter
                          ? `${location.value} (${location.ecommerceKey})`
                          : location.label}
                      </div>
                    </BrandWrapper>
                  )}
                </Item>
              ))}
            </ItemsList>
          </div>
        )}
      </CategoryOverlayWrapper>
    </OriginDestinationMobileWrapper>
  );
};

const OriginDestinationMobileWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  padding: 16px;
`;

const BreadCrumbs = styled.div`
  margin: ${fdsSizeSm} 0;
  border-bottom: 1px solid ${fdsComponentSecondaryColorDisabled};
  padding: 0 0 12px 0;
  line-height: 21px;

  & .ant-avatar-string {
    transform: scale(1) translateX(-50%) !important;
  }
`;

const BreadCrumb = styled.span`
  ${({ active }) =>
    active
      ? css`
          color: ${primaryColorToken};
          font-weight: bold;
        `
      : ''}
`;

const ItemsList = styled.div`
  height: 63vh;
  overflow: auto;
  margin: ${fdsSizeMd} 0 0 0;
`;

const Item = styled.div`
  border-top: 1px solid ${fdsComponentSecondaryColorDisabled};
  padding: ${fdsSizeSm} 0;
  padding: ${fdsSizeSm};

  &:hover {
    background: ${fdsComponentTertiaryColorClick};
  }

  ${({ active }) =>
    active
      ? css`
          background: ${fdsComponentTertiaryColorClick};
        `
      : ''}
`;

const StyledAvatar = styled(Avatar)`
  & .ant-avatar-string {
    top: -1px;
  }

  &.ant-avatar-sm {
    width: ${fdsComponentSpacingLg};
    height: ${fdsComponentSpacingLg};
    line-height: ${fdsComponentSpacingLg};
    top: -1px;
  }
  ${({ active }) =>
    active
      ? css`
          border: 1px solid ${primaryColorToken} !important;
        `
      : ''}
`;

const Pipe = styled.span`
  margin: 0 ${fdsComponentSpacingSm};
`;

const StyledTypeItem = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  & .anticon {
    margin: 0 8px 0 0;
  }
`;

const BrandWrapper = styled.div`
  display: flex;

  > div:nth-child(1) {
    align-self: center;
  }

  > div:nth-child(2) {
    margin: 0 0 0 8px;
    line-height: 18px;
  }
`;

const mapStateToProps = (store, { type, isUps }) => ({
  isMobile: isMobileSelector(store),
  getOriginCountries: getOriginCountries(store),
  getDestinationCountries: getDestinationCountries(store),
  shouldDisableAmazon: shouldDisableAmazonSelector(store),
  locations: store.search.locationsData[type],
  warehouses: store.search.locationsData.destination.brandLocations,
  isUps,
  brandCountries: store.search.locationsData.destination.brandCountries,
  pristine: store.search.pristine[type]
});

export default connect(mapStateToProps, {
  updateField,
  getPorts,
  getLocations,
  getAddressBookAddresses,
  setUserAddressFromAddressBook
})(OriginDestinationMobile);
