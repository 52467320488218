import { actions as layoutActions } from 'layout';
import { push } from 'microfronts-router';
import { map, zipObject } from 'lodash/fp';
import { Message } from '@freightos/design-system';
import { doGetRequest, doPostRequest, doPutRequest, getApiCall } from 'propera/HTTP';
import { actions as monitoringActions } from 'propera/monitoring';
import { model as userModel } from 'propera/user';
import { model as siteConfigModel } from 'propera/siteConfig';
import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  actionTypes as resultsActionTypes,
  noCoverageResults,
  quotesPolling,
  quotesPollingCompleted,
  showLoaderOnResults
} from 'results/results.actions';
import { searchResultsAnalyticsData, isPollingSelector } from 'results/results.selectors';
import { isOldRfqVersion, mapOldRfqLocations } from 'results/results.utils';
import {
  clearServicePredictions,
  GET_RFQ,
  GET_RFQ_SUMMARY,
  searchAnalytics,
  setUserAddressFromAddressBook,
  UPDATE_RFQ,
  updateField,
  GET_ADDRESS_BOOK_SINGLE_ADDRESS,
  getSingleUserAddressFromAddressBook
} from 'slimSearch/actions';
import {
  ANALYTICS_EVENT_NAME,
  CUSTOM_QUOTE_ROUTE,
  ENDPOINTS,
  PAGE_ROUTE,
  pollingDelay
} from 'slimSearch/constants';
import {
  getRfqSelector,
  isInScopeSelector,
  knownShipperSelector,
  fullFillmentLocationsSelector,
  rfqFromQuoteSelector
} from 'slimSearch/selectors';
import { mapRfqToQuote } from 'slimSearch/utils/mapOpenFreight.utils';
import { endpointConstructor } from 'utils';
import { v4 as uuidv4 } from 'uuid';

const { getDomain } = siteConfigModel;

export function* getShipmentQuotePayload() {
  const messageHeader = {
    messageID: uuidv4()
  };
  const knownShipper = yield select(knownShipperSelector);
  const userEmail = yield select(userModel.getEmail) || 'buyer@dc-team.com';
  const businessInfo = {
    serviceName: 'Quoting',
    serviceMethod: 'New',

    messageDateTime: new Date().toISOString(),
    parties: [
      {
        partyTypeCode: 'BY',
        contact: {
          electronicMail: userEmail
        },
        knownShipper
      },
      {
        partyTypeCode: 'FR',
        name: 'freightos.com'
      }
    ]
  };
  const shipment = yield select(getRfqSelector);
  return {
    messageHeader,
    businessInfo,
    shipment
  };
}

export function* updateRfqSaga({ payload }) {
  yield put({ type: UPDATE_RFQ.PENDING });
  const data = yield call(getShipmentQuotePayload);
  const endpoint = endpointConstructor(ENDPOINTS.updateRfq, { rfqKey: payload.rfqKey });
  let response;
  try {
    response = yield call(doPutRequest, endpoint, data);

    yield put({
      type: UPDATE_RFQ.SUCCESS,
      payload: response
    });

    // this is because we want to have in the reducer the data that comes from the server
    yield put({
      type: GET_RFQ_SUMMARY.type,
      payload: response.messageHeader?.conversationID
    });
  } catch (error) {
    yield put({
      type: UPDATE_RFQ.FAILURE,
      payload: response
    });
    yield put(searchAnalytics({ name: ANALYTICS_EVENT_NAME.SS_SEARCH_BUTTON_FAIL }));
    yield put(monitoringActions.exceptionReporter(error, false));
    if (error.status !== 401) {
      yield put(layoutActions.showTimeoutScreen());
    }
  }
}

export function* getRfqSaga({ payload, meta }) {
  const rfqFromQuote = yield select(rfqFromQuoteSelector);
  yield put({ type: GET_RFQ.PENDING });
  const data = yield call(getShipmentQuotePayload);
  const searchEndpoint = meta.keepRfq
    ? endpointConstructor(ENDPOINTS.searchAgainKeepRfq, { originalRfqKey: rfqFromQuote })
    : endpointConstructor(ENDPOINTS.search);

  const endpoint =
    payload === 'without-polling'
      ? ENDPOINTS.rfqSummaryWithoutPulling //(without triggering the search result process )
      : searchEndpoint; //with triggering the search result process

  let response;
  try {
    response = yield call(doPostRequest, endpoint, data);

    yield put({
      type: GET_RFQ.SUCCESS,
      payload: response
    });

    // this is because we want to have in the reducer the data that comes from the server
    yield put({
      type: GET_RFQ_SUMMARY.type,
      payload: response.messageHeader?.conversationID
    });
  } catch (error) {
    yield put({
      type: GET_RFQ.FAILURE,
      payload: response
    });
    // @TODO: catch error.status here and respond with error page
    if (error.status === 504) {
      Message.warning("Something's not right. Please try in a few minutes.", 10, null);
      yield put(push(`/${PAGE_ROUTE}`));
    }

    yield put(searchAnalytics({ name: ANALYTICS_EVENT_NAME.SS_SEARCH_BUTTON_FAIL }));
    yield put(monitoringActions.exceptionReporter(error, false));
    if (error.status !== 401) {
      yield put(layoutActions.showTimeoutScreen());
    }
  }
}

export function* fixApiLocationProblem(shipment) {
  if (isOldRfqVersion(shipment)) {
    const fullFillmentLocations = yield select(fullFillmentLocationsSelector);
    const fields = mapOldRfqLocations(shipment, fullFillmentLocations);
    yield all(fields.map(({ path, value }) => put(updateField(path, value))));
  }
}

export function* getSingleAddressBookAddress({ payload }) {
  try {
    yield put({ type: GET_ADDRESS_BOOK_SINGLE_ADDRESS.PENDING });
    const { data, status } = yield call(getApiCall, {
      payload: {
        path: endpointConstructor(ENDPOINTS.addressBookSingleAddress, {
          id: payload.id
        }),
        fullResponse: true
      }
    });

    if (status === 200) {
      yield put(setUserAddressFromAddressBook({ type: payload.type, address: data }));
    } else {
      yield put(setUserAddressFromAddressBook({ type: payload.type, address: null }));
      yield put(
        monitoringActions.exceptionReporter(
          'RFQ had address book address(es), but belonging to different user',
          false
        )
      );
    }
  } catch (error) {
    yield put(monitoringActions.exceptionReporter(error, false));
  }
}

export function* getRfqSummary({ payload }) {
  let response;
  // @todo check if the rfq already exists in the reducer and then don't call the api
  // the problem is that we have to fire the summary success (with the data) since a lot of
  // actions listen to it
  const domain = yield select(getDomain);

  try {
    response = yield call(
      doGetRequest,
      endpointConstructor(ENDPOINTS.rfqSummary, { rfqId: payload })
    );

    if (response.shipment.additionalInformation) {
      const additionalInfo = response.shipment.additionalInformation;

      const additionalInformationKeys = zipObject(
        map('key', additionalInfo),
        map('value', additionalInfo)
      );

      if (additionalInformationKeys.originUserAddressId) {
        yield put(
          getSingleUserAddressFromAddressBook({
            type: 'origin',
            id: additionalInformationKeys.originUserAddressId
          })
        );
      }

      if (additionalInformationKeys.destinationUserAddressId) {
        yield put(
          getSingleUserAddressFromAddressBook({
            type: 'destination',
            id: additionalInformationKeys.destinationUserAddressId
          })
        );
      }
    }

    yield put({
      type: GET_RFQ_SUMMARY.SUCCESS,
      payload: {
        quote: mapRfqToQuote(response),
        rfq: payload
      }
    });

    if (!domain.includes('ship.')) {
      // we want to do manual fix (send the user to services from results page )
      // look at init_results_page saga
      yield call(fixApiLocationProblem, response.shipment);
    }
  } catch (error) {
    yield put({
      type: GET_RFQ_SUMMARY.FAILURE,
      payload: response
    });

    yield put(monitoringActions.exceptionReporter(error, true));
    if (error.status !== 401) {
      yield put(layoutActions.showTimeoutScreen());
    }
  }
}

export function* quotesPollingSaga({ payload }) {
  const isPolling = yield select(isPollingSelector);
  if (!isPolling) {
    return;
  }
  yield put(showLoaderOnResults(true));
  try {
    let count = payload.count || 0;

    const startTime = payload.startTime ? payload.startTime : Date.now();
    // give limit for first search results

    if (Date.now() - startTime > 1000 * 60 * 1 && payload.count === 0) {
      // no results
      yield put(
        quotesPollingCompleted({
          status: 'timeout',
          rfqKey: payload.messageHeader?.conversationID
        })
      );
      return;
    }
    const { next } = payload.paging;
    const response = yield call(doGetRequest, next);

    yield put({
      type: resultsActionTypes.QUOTES_POLLING.SUCCESS,
      payload: response
    });

    if (response.paging?.hasMore) {
      yield delay(pollingDelay);
      yield put(
        quotesPolling({ ...response, startTime, count: count + (response.quotes?.length ?? 0) })
      );
    } else {
      yield put(
        quotesPollingCompleted({
          status: 'done',
          rfqKey: response.messageHeader?.conversationID,
          count: count + (response.quotes?.length ?? 0)
        })
      );
    }
  } catch (error) {
    yield put({ type: resultsActionTypes.QUOTES_POLLING.FAILURE });
    yield put(quotesPollingCompleted({ status: 'failure' }));
    yield put(monitoringActions.exceptionReporter(error, true));
    if (error.status !== 401) {
      yield put(layoutActions.showTimeoutScreen());
    }
  }
}

// eslint-disable-next-line require-yield
export function* quotesPollingSuccess({ payload }) {
  if (window.localStorage.getItem('debug') === 'true') {
    // eslint-disable-next-line no-console
    console.log('pull quotes success', payload);
  }
}

// eslint-disable-next-line no-unused-vars
export function* quotesPollingCompletedSaga({ payload = {} }) {
  const isInScope = yield select(isInScopeSelector);
  if (window.localStorage.getItem('debug') === 'true') {
    // eslint-disable-next-line no-console
    console.log('pull quotes completed', payload);
  }
  if (payload.status === 'failure') {
  }

  if (payload.status === 'timeout') {
    yield put(
      searchAnalytics({
        name: ANALYTICS_EVENT_NAME.SEARCH_RESULTS_FETCHED_COMPLETED_WITH_TIMEOUT,
        rfqKey: payload.rfqKey
      })
    );
    if (isInScope) {
      yield put(push(`/${CUSTOM_QUOTE_ROUTE}/${payload.rfqKey}`));
    } else {
      yield put(noCoverageResults());
    }
  } else if (payload.status === 'done') {
    if (payload.count === 0) {
      yield put(
        searchAnalytics({
          name: ANALYTICS_EVENT_NAME.SEARCH_RESULTS_FETCHED_COMPLETED_WITH_NO_QUOTES,
          rfqKey: payload.rfqKey
        })
      );
      if (isInScope) {
        yield put(push(`/${CUSTOM_QUOTE_ROUTE}/${payload.rfqKey}`));
      } else {
        yield put(noCoverageResults());
      }
      yield put(clearServicePredictions());
    } else {
      const data = yield select(searchResultsAnalyticsData(payload.rfqKey));
      yield put(
        searchAnalytics({
          name: ANALYTICS_EVENT_NAME.SEARCH_RESULTS_FETCHED,
          data
        })
      );
    }
  } else if (payload.status === 'booked' || payload.status === 'custom-quote') {
    // the custom quote flag will be fired when seller /sysadmin asked for custom quote before the polling finished
    const data = yield select(searchResultsAnalyticsData(payload.rfqKey));
    yield put(
      searchAnalytics({
        name: ANALYTICS_EVENT_NAME.SEARCH_RESULTS_PARTIALLY_FETCHED,
        data
      })
    );
  }

  yield put(showLoaderOnResults(false));

  // we will also need to trigger that when a user is booking without waiting the search to finish loading
  //yield put(setSearchSessionIdOff());
}

export default function* () {
  yield all([
    takeLatest(GET_RFQ.type, getRfqSaga),
    takeLatest(UPDATE_RFQ.type, updateRfqSaga),
    takeLatest(GET_RFQ_SUMMARY.type, getRfqSummary),
    takeEvery(GET_ADDRESS_BOOK_SINGLE_ADDRESS.type, getSingleAddressBookAddress),
    takeLatest(resultsActionTypes.QUOTES_POLLING.type, quotesPollingSaga),
    takeLatest(resultsActionTypes.START_POLLING, quotesPollingSaga),
    takeLatest(resultsActionTypes.QUOTES_POLLING.SUCCESS, quotesPollingSuccess),
    takeLatest(resultsActionTypes.QUOTES_POLLING_COMPLETED, quotesPollingCompletedSaga)
  ]);
}
