import React from 'react';
import { flatten, flow, values, concat, upperFirst, sortBy, lowerCase } from 'lodash/fp';
import { FixedSizeList as List } from 'react-window';
import styled from 'styled-components/macro';

import { connect } from 'microfronts-redux';
import siteConfigModel from 'propera/siteConfig';
import { Select, Option, Icon, Col, CountriesSelect } from '@freightos/design-system';
import { fdsComponentTertiaryColorHover } from '@freightos/design-system/dist/tokens';
import {
  getLocations,
  getPorts,
  setUserAddressFromAddressBook,
  updateField
} from 'slimSearch/actions';
import {
  ADDRESS_TYPES,
  SECTIONS,
  MIN_CHARS_TO_START_SEARCH,
  MOST_COMMON_ORIGIN_COUNTRIES,
  MOST_COMMON_DESTINATION_COUNTRIES
} from 'slimSearch/constants';
import {
  countryCodeToCountryName,
  filteredCountriesList,
  filteredLocationsList,
  addressBookAddresses,
  countryNameToCountryCode
} from 'slimSearch/utils';

import { t } from 'utils/translationProvider';
import { FieldError, StyledFormItem, AddressBookItem } from 'slimSearch/sharedStyles';
import { Brand } from 'slimSearch/components/sections/locations/Brand';

const { getOriginCountries, getDestinationCountries } = siteConfigModel;

export const LocationSelect = ({
  locationsData,
  updateField,
  getPorts,
  getLocations,
  stateType,
  type,
  getOriginCountries,
  getDestinationCountries,
  error,
  brandCountries,
  brandLocations,
  setUserAddressFromAddressBook
}) => {
  const [search, setSearch] = React.useState('');
  const [searchCityOrPort, setSearchCityOrPort] = React.useState('');

  const locationType = stateType[`${type}Location`];
  const isPort = locationType.locationTypeCode === ADDRESS_TYPES.PORT.value;
  const isFulfilmentCenter =
    locationType.locationTypeCode === ADDRESS_TYPES.FULFILMENT_CENTER.value;
  const isUps = locationType.locationTypeCode === ADDRESS_TYPES.LAST_MILE_DELIVERY.value;
  const listOfBrandLocations = flow([values, flatten])(brandLocations);
  const isStaticCenters = isUps || isFulfilmentCenter;

  const SMALL_VIRTUAL_ITEM_HEIGHT = 35;
  const VIRTUAL_ITEM_HEIGHT = !isFulfilmentCenter ? SMALL_VIRTUAL_ITEM_HEIGHT : 60;

  const onSearch = (value) => setSearch(value);

  const countryList = () => {
    if (isUps) {
      return brandCountries.map((country) => country.code);
    }

    if (isFulfilmentCenter) {
      return filteredCountriesList(type, listOfBrandLocations || []).map((country) => country.code);
    }

    return type === SECTIONS.DESTINATION ? getDestinationCountries : getOriginCountries;
  };

  const locationItem = (location) => {
    if (location.type === 'address-book') {
      return (
        <AddressBookItem className="no-click-outside">
          <div>
            <Icon className="no-click-outside" type={location.type} size="small" />
          </div>
          <div className="no-click-outside">
            <strong className="no-click-outside">{upperFirst(location.company)}</strong>
            <br />
            {location.address}, {location.city} {location.zipCode}
          </div>
        </AddressBookItem>
      );
    }

    return (
      <>
        {location.type && <Icon type={location.type} size="small" />} {location.label}
      </>
    );
  };

  return (
    <LocationSelectWrapper>
      <StyledColNoScroll span={24 / 3}>
        <StyledCountryFormItem colon={false} label={t('Country', 'Country')}>
          <StyledCountriesSelect
            includedCountries={countryList()}
            defaultDropdownWidth={300}
            dropdownMatchSelectWidth={false}
            mostUsedCountries={
              type === SECTIONS.ORIGIN
                ? MOST_COMMON_ORIGIN_COUNTRIES.map(lowerCase)
                : MOST_COMMON_DESTINATION_COUNTRIES.map(lowerCase)
            }
            data-test-id={`${type}-country-select`}
            value={[countryCodeToCountryName(locationType.countryID.value)]}
            disabled={countryList().length === 1}
            filterOption={false}
            onChange={(value) => {
              if (locationType.countryID.value !== countryNameToCountryCode(value[0])) {
                updateField(
                  `quote.${type}.${type}Location.countryID.value`,
                  countryNameToCountryCode(value[0])
                );
              }
            }}
          />
        </StyledCountryFormItem>
      </StyledColNoScroll>
      <StyledColNoScroll span={24 / 3}>
        <StyledFormItem colon={false} label={t('Address', 'Address')}>
          {isStaticCenters ? (
            <Location
              data-test-id={`${type}-address-select`}
              showSearch
              showArrow={false}
              disabled={!locationType.countryID.value}
              filterOption={isStaticCenters}
              notFoundContent={null}
              placeholder={isUps ? 'Select city' : 'Select fulfilment center'}
              onSearch={(searchTerm) => {
                onSearch(searchTerm);
              }}
              value={locationType.locationCode}
              onChange={(value, options) => {
                onSearch('');
                updateField(`quote.${type}.${type}Location.locationCode`, value);
                updateField(`quote.${type}.${type}Location.locationType`, options.props.type);
              }}
              dropdownStyle={{ width: '300px' }}
              dropdownMatchSelectWidth={false}
              dropdownRender={() => {
                const locations = sortBy(
                  ['value'],
                  filteredLocationsList(
                    listOfBrandLocations,
                    locationType.countryID.value,
                    isUps,
                    search
                  )
                );

                const height = () =>
                  locations.length <= 6
                    ? locations.length * VIRTUAL_ITEM_HEIGHT
                    : 6 * VIRTUAL_ITEM_HEIGHT;

                return (
                  <List
                    overscanCount={8}
                    height={height()}
                    itemCount={locations.length}
                    itemSize={VIRTUAL_ITEM_HEIGHT}
                  >
                    {({ index, style }) => {
                      const location = locations[index];

                      return (
                        <StyledLocationOption
                          style={{ ...style }}
                          data-test-id={`${type}-address-select-value-${location.id}`}
                          key={location.id}
                          onMouseDown={() => {
                            onSearch('');
                            updateField(
                              `quote.destination.destinationLocation.locationCode`,
                              location.value
                            );
                            updateField(
                              `quote.destination.destinationLocation.label`,
                              location.label
                            );
                            updateField(
                              `quote.destination.destinationLocation.ecommerceKey`,
                              location.ecommerceKey
                            );
                            updateField(
                              `quote.destination.destinationLocation.brand`,
                              location.brand
                            );
                          }}
                        >
                          <StyledBrand>
                            <Brand name={location.brand} />
                          </StyledBrand>{' '}
                          <StyledLabel>
                            {location.value} ({location.ecommerceKey})
                          </StyledLabel>
                        </StyledLocationOption>
                      );
                    }}
                  </List>
                );
              }}
            />
          ) : (
            <Location
              showSearch
              showArrow={false}
              dropdownMatchSelectWidth={false}
              disabled={!locationType.countryID.value}
              filterOption={false}
              data-test-id={`${type}-address-select`}
              notFoundContent={null}
              optionLabelProp={isPort ? 'value' : 'label'}
              dropdownStyle={{ maxHeight: '300px' }}
              placeholder={isPort ? 'Enter port/airport code' : 'Enter city or zip code'}
              onSearch={(searchTerm) => {
                if (searchTerm.length >= MIN_CHARS_TO_START_SEARCH) {
                  isPort
                    ? getPorts({ country: locationType.countryID.value, searchTerm, type })
                    : getLocations({ country: locationType.countryID.value, searchTerm, type });
                }
                setSearchCityOrPort(searchTerm);
              }}
              value={locationType.locationCode}
              onChange={(value, options) => {
                updateField(
                  `quote.${type}.${type}Location.locationCode`,
                  options.props.addressValue
                );
                if (options.props.type === 'address-book') {
                  setUserAddressFromAddressBook({ type, address: options.props });
                  updateField(
                    `quote.${type}.${type}Location.locationAddressBookId`,
                    options.props?.addressId
                  );
                  updateField(
                    `quote.${type}.${type}Location.locationZipCode`,
                    options.props?.addressZip
                  );
                  updateField(
                    `quote.${type}.${type}Location.locationCompany`,
                    options.props?.company
                  );
                  updateField(
                    `quote.${type}.${type}Location.stateOrProvince`,
                    options.props?.stateOrProvince
                  );
                } else {
                  setUserAddressFromAddressBook({ type, address: null });
                  updateField(`quote.${type}.${type}Location.locationAddressBookId`, null);
                  updateField(`quote.${type}.${type}Location.locationZipCode`, null);
                  updateField(`quote.${type}.${type}Location.locationCompany`, null);
                  updateField(`quote.${type}.${type}Location.stateOrProvince`, null);
                }
                updateField(`quote.${type}.${type}Location.locationType`, options.props.type);
                setSearchCityOrPort('');
              }}
            >
              {/* @TODO: extract to fn to properly condition, also deal with mobile view */}
              {!isPort && searchCityOrPort.length < MIN_CHARS_TO_START_SEARCH
                ? (addressBookAddresses(locationsData, searchCityOrPort) || []).map((location) => (
                    <Option
                      value={location.id || location.value}
                      key={location.id || location.value}
                      type={location.type}
                      addressValue={location.value}
                      addressId={location.id}
                      addressZip={location.zipCode}
                      {...(location?.company ? { company: location?.company } : null)}
                      {...(location?.stateOrProvince
                        ? { stateOrProvince: location?.stateOrProvince }
                        : null)}
                    >
                      {locationItem(location)}
                    </Option>
                  ))
                : (
                    concat(
                      locationsData[isPort ? 'ports' : 'addresses'],
                      !isPort ? addressBookAddresses(locationsData, searchCityOrPort) : []
                    ) || []
                  ).map((location) => (
                    <Option
                      value={location.id || location.value}
                      key={location.id || location.value}
                      type={location.type}
                      addressValue={location.value}
                      addressZip={location.zipCode}
                      addressId={location.id}
                      {...(location?.company ? { company: location?.company } : null)}
                      {...(location?.stateOrProvince
                        ? { stateOrProvince: location?.stateOrProvince }
                        : null)}
                    >
                      {locationItem(location)}
                    </Option>
                  ))}
            </Location>
          )}
        </StyledFormItem>
        {error ? <FieldError>Required&nbsp;</FieldError> : <div>&nbsp;</div>}
      </StyledColNoScroll>
    </LocationSelectWrapper>
  );
};

const LocationSelectWrapper = styled.div``;

const StyledCountryFormItem = styled(StyledFormItem)`
  .ant-avatar-sm {
    position: absolute !important;
    width: 18px !important;
    height: 18px !important;
  }
`;

const Location = styled(Select)``;

const StyledColNoScroll = styled(Col)`
  overflow-x: hidden; // MPS-3954
`;

const StyledCountriesSelect = styled(CountriesSelect)``;

const StyledLocationOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;

  &:hover {
    background: ${fdsComponentTertiaryColorHover};
    cursor: pointer;
  }
`;

const StyledLabel = styled.span``;

const StyledBrand = styled.span`
  svg {
    position: relative;
    top: 3px;
    margin-right: 16px;
  }
`;

const mapStateToProps = (store, { type }) => ({
  locationsData: store.search.locationsData[type],
  stateType: store.search.quote[type],
  getOriginCountries: getOriginCountries(store),
  getDestinationCountries: getDestinationCountries(store),
  brandLocations: store.search.locationsData.destination.brandLocations,
  brandCountries: store.search.locationsData.destination.brandCountries
});

export default connect(mapStateToProps, {
  updateField,
  getPorts,
  getLocations,
  setUserAddressFromAddressBook
})(LocationSelect);
